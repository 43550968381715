<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-6 px-0">
    <div>
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/control-center'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Account Settings</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="#"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >Logs</a
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Start  -->
    <div class="px-3">
      <div class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex pr-3 text-lg leading-6 font-medium text-gray-900">
            <CodeIcon class="h-5 w-5 text-red-300" />
            <div class="px-5 my-auto">Logs</div>
          </div>
          <p class="mt-1 mb-5 max-w-2xl text-sm text-gray-500">
            Logs associated with installing and removing gateways and devices
          </p>

          <!-- Start table -->
          <div class="grid grid-cols-3">
            <div
              class="relative col-span-2 text-gray-400 focus-within:text-gray-600 ml-0 h-10 px-0 mb-5"
            >
              <div
                class="absolute z-10 inset-y-3 left-3 flex items-center pointer-events-none"
              >
                <SearchIcon class="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                class="filter w-full px-10 py-2 text-sm font-small rounded-md border-transparent drop-shadow text-gray-500 placeholder-gray-400 focus:outline-none focus:placeholder-gray-300 focus:ring-0 sm:text-md"
                placeholder="type install:true for all installs"
                type="search"
                v-on:input="inputSearch"
              />
            </div>
            <div
              class="col-span-2 my-0 overflow-x-auto sm:-mx-0 lg:-mx-0 h-96 overflow-auto"
            >
              <div
                class="py-0 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-0"
              >
                <table class="w-full">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="sr-only px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        diff
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        EUI
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ID
                      </th>

                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Base URL
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        User
                      </th>

                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created
                      </th>
                      <th scope="col" class="sr-only">BEFORE</th>
                      <th scope="col" class="sr-only">AFTER</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr
                      v-for="log in search_result_logs"
                      :key="log.description"
                      class="align-top"
                    >
                      <td>
                        <button
                          type="button"
                          v-on:click="selected_diff = JSON.parse(log.diff)"
                          class="mt-2 px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                        >
                          Diff
                        </button>
                      </td>
                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        <div v-if="log.dev_eui">
                          <router-link :to="'/devices/' + log.object_id">
                            {{ log.dev_eui }}
                          </router-link>
                        </div>
                        <div v-else-if="log.gateway_eui">
                          <router-link :to="'/gateways/' + log.object_id">
                            {{ log.gateway_eui }}
                          </router-link>
                        </div>
                      </td>

                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        <div v-if="log.core_device_id">
                          <router-link :to="'/devices/' + log.object_id">
                            {{ log.core_device_id }}
                          </router-link>
                        </div>
                        <div v-else-if="log.core_gateway_id">
                          <router-link :to="'/gateways/' + log.object_id">
                            {{ log.core_gateway_id }}
                          </router-link>
                        </div>
                      </td>

                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        {{ log.base_url }}
                      </td>
                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        {{ log.user_email }}
                      </td>

                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        {{ formatDate(log.created_at) }}
                      </td>
                      <td>
                        <button
                          type="button"
                          v-on:click="selected_diff = JSON.parse(log.old_state)"
                          class="mt-2 px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                        >
                          old
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          v-on:click="selected_diff = JSON.parse(log.new_state)"
                          class="mt-2 px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                        >
                          new
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="text-xs font-xs border-2 rounded-lg ml-5 bg-gray-100 p-2 h-96 overflow-auto"
            >
              <pre><vue-json-pretty :data="selected_diff" :showDoubleQuotes=false /></pre>
            </div>
          </div>

          <!-- end table --->
        </div>
      </div>
    </div>
    <!-- Finish section --->
  </div>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  LockClosedIcon,
  DotsHorizontalIcon,
  CodeIcon,
  BanIcon,
} from '@heroicons/vue/solid';
import axios from 'axios';
import dateformat from 'dateformat';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { SearchIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    LockClosedIcon,
    DotsHorizontalIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    CodeIcon,
    BanIcon,
    VueJsonPretty,
    SearchIcon,
  },

  setup() {
    return {};
  },
  data() {
    return {
      logs: [],
      search_result_logs: [],
      selected_diff: {
        click: 'Diff',
        to_see: {
          differences: ['before', 'after'],
        },
        event: 'created',
      },
    };
  },
  methods: {
    formatDate(date_string) {
      if (date_string) {
        let date = new Date(date_string);
        return dateformat(date, 'dddd, mmm dS, yyyy, h:MM TT');
      }
    },

    inputSearch(event) {
      this.latestSearchString = event.target.value;

      setTimeout(
        function (currentSearchString) {
          //still the same after 2 seconds, then we know the user
          //has stopped typing

          this.search_result_logs = [];
          this.selected_diff = { click: 'Diff' };

          if (currentSearchString == this.latestSearchString) {
            //console.log('Searching: ' + currentSearchString);

            if (currentSearchString.length > 0) {
              let search_array = currentSearchString.split(':');
              let search_key = search_array[0];
              let search_value = search_array[1];
              console.log('search_key: ' + search_key);
              console.log('search_value:' + search_value);

              for (let log of this.logs) {
                if (log.diff != null) {
                  let diff_object = JSON.parse(log.diff);

                  //sometimes a user may just leave the ":" on without using a value
                  //If this is the case, let's just remove it
                  if (search_key.endsWith(':')) {
                    search_key = search_key.slice(0, -1);
                  }
                  console.log('search_key: ' + search_key);
                  if (Object.hasOwn(diff_object, search_key)) {
                    if (search_value != null && search_value.length > 0) {
                      //We have a value we need to check against
                      //If it's null, then we add all objects that have that key, regardless of the value
                      //for example - a user may search for "install" in which case we return everything with
                      //install:true and install:false. If they search for "install:true" then we just return this

                      //Let's convert everything to a string to we can do a comparison
                      // let prop_value = '';
                      // let prop_type = typeof diff_object.search_key;
                      // if(prop_type == "boolean"){
                      //   prop_value =
                      // }
                      // if(diff_object.search_key){

                      // }
                      console.log('diff_object');
                      console.log(diff_object);
                      console.log(
                        'diff_object.search_key: ' + diff_object[search_key]
                      );
                      console.log(
                        'diff_object.search_key.toString(): ' +
                          diff_object[search_key].toString()
                      );

                      if (diff_object[search_key].toString() == search_value) {
                        this.search_result_logs.push(log);
                      }
                    } else {
                      this.search_result_logs.push(log);
                    }
                  }
                }
              }
            } else {
              //We have removed all search params so we add all of the logs to the search_result_logs
              this.search_result_logs = this.logs;
              this.selected_diff = { click: 'Diff' };
            }
          }
        }.bind(this),
        1000,
        event.target.value
      );
    },

    loadPage() {
      this.loadLogs();
    },

    loadLogs() {
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;
      console.log('loadLogs is run');
      axios
        .get(
          `/logs?core_account_name=${currentAccountSession}&core_project_name=${currentProjectSession}`
        )
        .then((res) => {
          //console.log(res);
          this.logs = res.data.data;
          this.search_result_logs = this.logs;
        })
        .catch((err) => {});
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    console.log('Devices created called!!');
    this.loadPage();
  },
};
</script>
