<template>
  <div class="py-6 px-6">
    <div class="flex justify-end p-2">
      <button
        type="button"
        v-on:click="downloadCSV"
        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Download CSV
      </button>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-for="name in headers"
                    :key="name"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowIdx) in dataRows"
                  :key="row"
                  :class="rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    v-for="element in row"
                    :key="element"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ element }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import { saveAs } from 'file-saver';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';

export default {
  components: {
    ServerResponse,
    AlertBanner,
  },

  data() {
    return {
      dataBlob: '',
      dataRows: [],
      headers: [],
      serverResponse: '',
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    downloadCSV() {
      var FileSaver = require('file-saver');
      var blob = new Blob([this.dataBlob], {
        type: 'text/plain;charset=utf-8',
      });
      FileSaver.saveAs(blob, 'core_things_data.csv');
    },
  },

  created() {
    const currentAccountSession = this.$store.state.clime_account.name;
    axios
      .get('/reports/dump?core_account_name=' + currentAccountSession)
      .then((res) => {
        //console.log(res.data.data);
        this.dataBlob = res.data.data;

        let result = res.data.data.split('\n');
        for (let i = 0; i < result.length; i++) {
          if (i == 0) {
            //the header values
            this.headers = result[i].split(',');
          } else {
            this.dataRows.push(result[i].split(','));
          }
        }

        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((error) => {
        console.log(error);
        this.serverResponse = JSON.stringify(error, null, 2);
        this.raiseErrorAlert(error);
      });
  },

  unmounted() {
    this.$store.state.userAlert.visible = false;
  },
};
</script>
