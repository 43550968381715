<template>
  <div class="py-6 px-6">
    <div class="flex justify-end p-2">
      <button
        type="button"
        v-on:click="downloadCSV"
        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Download CSV
      </button>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Meter Reference Number
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    2022 Total
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Jan
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Feb
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Mar
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Apr
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    May
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Jun
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Jul
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Aug
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sep
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Oct
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nov
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Dec
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(meter, meterIdx) in meters"
                  :key="meter"
                  :class="meterIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ meter.reference_number }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ meter.type }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ meter.description }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).total) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).january) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).february) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).march) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).april) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).may) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).june) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).july) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).august) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).september) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).october) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).november) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ abbreviateNumber(usage_map.get(meter._id).december) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    colspan="3"
                  ></td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.year) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.january) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.february) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.march) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.april) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.may) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.june) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.july) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.august) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.september) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.october) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.november) }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold"
                  >
                    {{ abbreviateNumber(totals.december) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import { saveAs } from 'file-saver';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';

export default {
  components: {
    ServerResponse,
    AlertBanner,
  },

  data() {
    return {
      meters: [],
      serverResponse: '',
      usage_map: new Map(),
      totals: {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
        year: 0,
      },
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    downloadCSV() {
      var FileSaver = require('file-saver');
      var blob = new Blob([this.dataBlob], {
        type: 'text/plain;charset=utf-8',
      });
      FileSaver.saveAs(blob, 'core_things_data.csv');
    },
    abbreviateNumber(number) {
      // console.log('abbreviatingNumber: ' + number);
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) {
        // console.log('tier == 0');
        return number;
      }

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;
      // console.log('returning: ' + scaled.toFixed(1) + suffix);

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
  },

  created() {
    const currentAccountSession = this.$store.state.clime_account.name;
    const currentProjectSession = this.$store.state.clime_project.name;
    axios
      .get(
        '/meters?core_account_name=' +
          currentAccountSession +
          '&core_project_name=' +
          currentProjectSession
      )
      .then((res) => {
        //console.log(res.data.data);
        this.meters = res.data.data;
        for (let meter of this.meters) {
          let running_total = 0;
          let meter_entry = {};

          for (let report of meter.consumption_reports) {
            let start_date = new Date(report.start_date);
            // console.log('start_date.getFullYear: ' + start_date.getFullYear());
            // console.log('start_date.getMonth: ' + start_date.getMonth());
            if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 0
            ) {
              meter_entry.january = report.usage;
              running_total = running_total + report.usage;
              this.totals.january = this.totals.january + meter_entry.january;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 1
            ) {
              meter_entry.february = report.usage;
              running_total = running_total + report.usage;
              this.totals.february =
                this.totals.february + meter_entry.february;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 2
            ) {
              meter_entry.march = report.usage;
              running_total = running_total + report.usage;
              this.totals.march = this.totals.march + meter_entry.march;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 3
            ) {
              meter_entry.april = report.usage;
              running_total = running_total + report.usage;
              this.totals.april = this.totals.april + meter_entry.april;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 4
            ) {
              meter_entry.may = report.usage;
              running_total = running_total + report.usage;
              this.totals.may = this.totals.may + meter_entry.may;
              console.log('adding totals to may: ' + this.totals.may);
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 5
            ) {
              meter_entry.june = report.usage;
              running_total = running_total + report.usage;
              this.totals.june = this.totals.june + meter_entry.june;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 6
            ) {
              meter_entry.july = report.usage;
              running_total = running_total + report.usage;
              this.totals.july = this.totals.july + meter_entry.july;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 7
            ) {
              meter_entry.august = report.usage;
              running_total = running_total + report.usage;
              this.totals.august = this.totals.august + meter_entry.august;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 8
            ) {
              meter_entry.september = report.usage;
              running_total = running_total + report.usage;
              this.totals.september =
                this.totals.september + meter_entry.september;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 9
            ) {
              meter_entry.october = report.usage;
              running_total = running_total + report.usage;
              this.totals.october = this.totals.october + meter_entry.october;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 10
            ) {
              meter_entry.november = report.usage;
              running_total = running_total + report.usage;
              this.totals.november =
                this.totals.november + meter_entry.november;
            } else if (
              start_date.getFullYear() == 2022 &&
              start_date.getMonth() == 11
            ) {
              meter_entry.december = report.usage;
              running_total = running_total + report.usage;
              this.totals.december =
                this.totals.december + meter_entry.december;
            }
          }
          meter_entry.total = running_total;
          this.usage_map.set(meter._id, meter_entry);
          this.totals.year = this.totals.year + meter_entry.total;
        }
        // console.log('------usage_map-----------');
        // console.log(this.usage_map);

        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((error) => {
        console.log(error);
        this.serverResponse = JSON.stringify(error, null, 2);
        this.raiseErrorAlert(error);
      });
  },

  unmounted() {
    this.$store.state.userAlert.visible = false;
  },
};
</script>
