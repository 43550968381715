<template>
  <div class="py-6 px-6">
    <!-- Replace with your content -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <span class="text-lg leading-6 font-medium text-gray-900">
          Project Name: {{ project.core_project_name }}
        </span>
        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Project Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="project.core_project_name"
                    />
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div v-if="userAlertBottom.status == 'success'">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  {{ userAlertBottom.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <div v-if="!new_project">
              <feedback-button
                v-if="deleteButtonState == 'none'"
                v-on:click="confirmRemoveProject = true"
                :text="'Delete Project'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>

              <confirm-action
                v-if="confirmRemoveProject"
                :title_text="'Delete Project'"
                :message_text="'Are you sure you want to Delete this Project? This action cannot be undone!'"
                :confirm_button_text="'Delete Project'"
                @userAction="removeProject($event)"
              ></confirm-action>
            </div>

            <div v-if="new_project">
              <feedback-button
                v-if="submitButtonState == 'none'"
                v-on:click="addProject(project)"
                :text="'Add Project'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
            </div>
            <div v-else>
              <feedback-button
                v-if="submitButtonState == 'none'"
                v-on:click="updateProject"
                :text="'Submit'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
            </div>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';
import LoadingWidget from '../../components/LoadingWidget.vue';
import FeedbackButton from '../../components/FeedbackButton.vue';
import ConfirmAction from '../../components/ConfirmAction.vue';
import VueTagsInput from '@sipec/vue3-tags-input';
import { CheckCircleIcon, XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    ServerResponse,
    AlertBanner,
    LoadingWidget,
    FeedbackButton,
    ConfirmAction,
    VueTagsInput,
    CheckCircleIcon,
    XIcon,
  },

  setup() {
    return {};
  },

  data() {
    return {
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      confirmRemoveProject: false,
      confirmUserAction: false,
      showLoading: false,
      userAlertBottom: {
        status: '',
        message: '',
      },
      core_account_name: null,
      add_project: false,
      serverResponse: '',
      account: {},
      project: {},
    };
  },

  methods: {
    addProject(project) {
      this.submitButtonState = 'loading';
      this.account.core_projects.push(project);

      axios
        .put('/accounts/' + this.account._id, this.account)
        .then((res) => {
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 500);
          //Let's reload the full application as we have made a structural change to the accounts/projects model
          window.location.href = '/account-settings/projects';
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    updateProject() {
      this.submitButtonState = 'loading';

      axios
        .put('/accounts/' + this.account._id, this.account)
        .then((res) => {
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 500);
          //this.$router.push('/account-settings/projects/');
          //Let's reload the full application as we have made a structural change to the accounts/projects model
          window.location.href = '/account-settings/projects';
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    removeProject(userConfirmed) {
      console.log(
        'removeProject() called with userConfirmed: ' + userConfirmed
      );
      this.confirmRemoveProject = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';

        console.log('Account Object before any removal');
        console.log(this.account);

        let index = 0;
        for (let i = 0; i < this.account.core_projects.length; i++) {
          if (this.account.core_projects[i]._id == this.project._id) {
            index = i;
          }
        }

        this.account.core_projects.splice(index, 1);

        console.log('Account object being sent === =');
        console.log(this.account);

        axios
          .put('/accounts/' + this.account._id, this.account)
          .then((res) => {
            this.deleteButtonState = 'success';
            this.serverResponse = JSON.stringify(res, null, 2);
            window.location.href = '/account-settings/projects';
          })
          .catch((err) => {
            this.deleteButtonState = 'error';
            this.raiseErrorAlert(err);
            setTimeout(() => {
              this.deleteButtonState = 'none';
            }, 5000);
            this.serverResponse = JSON.stringify(err.response, null, 2);
          });
      }
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },

    loadPage() {
      this.core_account_name = this.$store.state.clime_account.name;
      this.current_user_email = this.$auth.user.value.email;

      axios
        .get('/accounts?account_name=' + this.core_account_name)
        .then((res) => {
          this.account = res.data.data[0];
          console.log(this.account);

          if (this.$route.path === '/account-settings/projects/new') {
            console.log('Creating new project');
            this.new_project = true;
          } else {
            let project_id = this.$route.params.id;

            for (let project of this.account.core_projects) {
              if (project._id == project_id) {
                this.project = project;
              }
            }
            this.new_project = false;
          }
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },
  created() {
    this.loadPage();
  },
};
</script>
