<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open = false"
    >
      <div
        class="flex items-end justify-center pt-4 px-4 pb-20 text-center block lg:block sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <SwitchHorizontalIcon
                  class="h-6 w-6 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-md leading-6 font-medium text-gray-900 mb-5"
                >
                  {{ title_text }}
                </DialogTitle>

                <div>
                  <Menu
                    as="div"
                    class="relative inline-block text-left h-12 w-auto bg-white"
                  >
                    <div>
                      <MenuButton
                        class="inline-flex py-4 w-full rounded-md shadow-sm px-4 py-2 bg-white text-medium font-sm text-gray-500 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800 focus:offset-gray-800"
                      >
                        <BriefcaseIcon
                          class="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        <p class="px-4">{{ selectedProject }}</p>
                        <ChevronDownIcon
                          class="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      </MenuButton>
                    </div>

                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="origin-top-right absolute right-0 mt-2 w-56 z-5 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                      >
                        <MenuItem
                          v-for="project in projectList"
                          :key="project.core_project_name"
                          v-on:click="chooseProject(project)"
                          v-slot="{ active }"
                        >
                          <a
                            href="#"
                            :class="[
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm',
                            ]"
                          >
                            <BriefcaseIcon
                              class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            {{ project.core_project_name }}
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="
                  (this.result.is_confirming = true),
                    (this.result.new_project = this.selectedProject),
                    userAction(this.result),
                    (open = false)
                "
              >
                {{ confirm_button_text }}
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                @click="
                  (this.result.is_confirming = false),
                    userAction(this.result),
                    (open = false)
                "
                ref="cancelButtonRef"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import {
  ExclamationIcon,
  CogIcon,
  SwitchHorizontalIcon,
  BriefcaseIcon,
  ChevronDownIcon,
} from '@heroicons/vue/outline';

export default {
  props: {
    title_text: { required: true, type: String },
    message_text: { required: true, type: String },
    core_account_name: { required: true, type: String },
    confirm_button_text: { required: false, type: String },
    action_url: { required: false, type: String },
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    SwitchHorizontalIcon,
    CogIcon,
    BriefcaseIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  data() {
    return {
      projectList: [],
      selectedProject: null,
      result: {
        is_confirming: false,
        new_project: null,
      },
    };
  },
  methods: {
    userAction(result) {
      this.$emit('user_action', this.result);
    },
    chooseProject(project) {
      this.selectedProject = project.core_project_name;
    },
  },
  created() {
    this.selectedProject = this.$store.state.clime_project.name;

    console.log('core_account_name: ' + this.core_account_name);
    // axios
    //   .get('/projects?core_account_name=' + this.core_account_name)
    //   .then((res) => {
    //     console.log(res.data);
    //     this.projectList = res.data.data;

    //     console.log(this.projectList);
    //   })
    //   .catch();

    axios
      .get('/accounts?account_name=' + this.core_account_name)
      .then((res) => {
        console.log(res.data);

        this.projectList = res.data.data[0].core_projects;
      })
      .catch();
  },

  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
};
</script>
