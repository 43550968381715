<template>
  <div
    class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
  >
    <dt>
      <div class="absolute bg-sky-500 rounded-md p-3">
        <RssIcon class="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
      </div>

      <p class="ml-16 text-sm font-medium text-gray-500 truncate">
        {{ name }}
      </p>
    </dt>
    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p v-if="live" class="text-2xl font-semibold text-gray-900">
        {{ value }} {{ unit }}
      </p>
      <p v-else class="text-2xl font-semibold text-red-500">---</p>

      <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
        <div class="text-sm">
          <router-link
            v-if="report == 'temperature'"
            :to="'/reports/temperatureReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'battery'"
            :to="'/reports/batteryReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'traffic'"
            :to="'/reports/trafficReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'humidity'"
            :to="'/reports/humidityReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'door'"
            :to="'/reports/doorReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'air_quality'"
            :to="'/reports/airQualityReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'carbon_dioxide'"
            :to="'/reports/carbonDioxideReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'light_level'"
            :to="'/reports/lightLevelReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'pm10'"
            :to="'/reports/pm10Report?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'pm2_5'"
            :to="'/reports/pm2_5Report?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'pressure'"
            :to="'/reports/pressureReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'electricity'"
            :to="'/reports/electricityReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'gas'"
            :to="'/reports/gasReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'current'"
            :to="'/reports/currentReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
          <router-link
            v-else-if="report == 'water_volume'"
            :to="'/reports/waterVolumeReport?search=' + core_device_id"
            class="font-medium text-sky-600 hover:text-gray-500"
          >
            View Data Stream
          </router-link>
        </div>
      </div>
    </dd>
  </div>
</template>

<script>
import { RssIcon } from '@heroicons/vue/outline';

export default {
  props: {
    name: { required: true, type: String },
    report: { required: true, type: String },
    value: { required: true, type: Number },
    unit: { required: false, type: String },
    live: { required: true, type: Boolean },
    core_device_id: { required: true, type: String },
  },
  components: {
    RssIcon,
  },
  data() {
    return {};
  },
};
</script>
