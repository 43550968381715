import { createRouter, createWebHistory } from 'vue-router';

import Home from './pages/Home.vue';
import Dashboard from './pages/Dashboard.vue';
import Devices from './pages/Devices.vue';
import Meters from './pages/Meters.vue';
import Buildings from './pages/Buildings.vue';
import Gateways from './pages/Gateways.vue';
import ControlCenter from './pages/ControlCenter.vue';
import Incidents from './pages/control-center/Incidents.vue';
import Events from './pages/control-center/Events.vue';
import Rules from './pages/control-center/Rules.vue';
import RuleDetail from './pages/control-center/RuleDetail.vue';
import NOC from './pages/NOC.vue';
import Admin from './pages/Admin.vue';
import Scan from './pages/Scan.vue';
import DeviceDetail from './pages/DeviceDetail.vue';
import MeterDetail from './pages/MeterDetail.vue';
import BuildingDetail from './pages/BuildingDetail.vue';
import GatewayDetail from './pages/GatewayDetail.vue';
import ProjectSettings from './pages/ProjectSettings.vue';
import AccountSettings from './pages/AccountSettings.vue';
import Account from './pages/account-settings/Account.vue';
import Preferences from './pages/project-settings/Preferences.vue';
import Subscriptions from './pages/account-settings/Subscriptions.vue';
import Billing from './pages/account-settings/Billing.vue';
import Logs from './pages/account-settings/Logs.vue';
import StripeCheckoutRedirect from './pages/project-settings/StripeCheckoutRedirect.vue';
import AccountUsers from './pages/account-settings/AccountUsers.vue';
import AccountUserDetail from './pages/account-settings/AccountUserDetail.vue';
import ProjectUsers from './pages/project-settings/ProjectUsers.vue';
import ProjectUserDetail from './pages/project-settings/ProjectUserDetail.vue';
import Projects from './pages/account-settings/Projects.vue';
import ProjectDetail from './pages/account-settings/ProjectDetail.vue';
import Reports from './pages/Reports.vue';
import PlatformSummaryReport from './pages/reports/PlatformSummaryReport.vue';
import EnergyReport from './pages/reports/EnergyReport.vue';
import TemperatureReport from './pages/reports/TemperatureReport.vue';
import CurrentReport from './pages/reports/CurrentReport.vue';
import PortfolioReport from './pages/reports/PortfolioReport.vue';
import CarbonDioxideReport from './pages/reports/CarbonDioxideReport.vue';
import HumidityReport from './pages/reports/HumidityReport.vue';
import AirQualityReport from './pages/reports/AirQualityReport.vue';
import DoorReport from './pages/reports/DoorReport.vue';
import InterruptReport from './pages/reports/InterruptReport.vue';
import ElectricityReport from './pages/reports/ElectricityReport.vue';
import GasReport from './pages/reports/GasReport.vue';
import TrafficReport from './pages/reports/TrafficReport.vue';
import TrafficVersusDoorReport from './pages/reports/TrafficVersusDoorReport.vue';
import TemperatureVersusDoorReport from './pages/reports/TemperatureVersusDoorReport.vue';
import TemperatureVersusCurrentReport from './pages/reports/TemperatureVersusCurrentReport.vue';
import BatteryReport from './pages/reports/BatteryReport.vue';
import WaterVolumeReport from './pages/reports/WaterVolumeReport.vue';
import MapReport from './pages/reports/MapReport.vue';
import WeeklyReport from './pages/reports/WeeklyReport.vue';
import RouteReport from './pages/reports/RouteReport.vue';
import ConversionReport from './pages/reports/ConversionReport.vue';
import DataReport from './pages/reports/DataReport.vue';
import Top10TrafficReport from './pages/reports/Top10TrafficReport.vue';
import store from './store.js';

const routes = [
  {
    name: '',
    path: '/',
    component: Home,
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
  },
  {
    name: 'scan',
    path: '/scan',
    component: Scan,
  },
  {
    name: 'devices',
    path: '/devices',
    component: Devices,
  },
  {
    name: 'meters',
    path: '/meters',
    component: Meters,
  },
  {
    name: 'buildings',
    path: '/buildings',
    component: Buildings,
  },
  {
    name: 'device-detail',
    path: '/devices/:id',
    component: DeviceDetail,
  },
  {
    name: 'meter-detail',
    path: '/meters/:id',
    component: MeterDetail,
  },
  {
    name: 'building-detail',
    path: '/buildings/:id',
    component: BuildingDetail,
  },
  {
    name: 'gateways',
    path: '/gateways',
    component: Gateways,
  },
  {
    name: 'gateway-detail',
    path: '/gateways/:id',
    component: GatewayDetail,
  },
  {
    name: 'control-center',
    path: '/control-center',
    component: ControlCenter,
  },
  {
    name: 'incidents',
    path: '/control-center/incidents',
    component: Incidents,
  },
  {
    name: 'events',
    path: '/control-center/events',
    component: Events,
  },
  {
    name: 'rules',
    path: '/control-center/rules',
    component: Rules,
  },
  {
    name: 'rule-detail',
    path: '/control-center/rules/:id',
    component: RuleDetail,
  },
  {
    name: 'noc',
    path: '/noc',
    component: NOC,
  },

  {
    name: 'admin',
    path: '/admin',
    component: Admin,
  },

  {
    name: 'project-settings',
    path: '/project-settings',
    component: ProjectSettings,
  },
  {
    name: 'account-settings',
    path: '/account-settings',
    component: AccountSettings,
  },
  {
    name: 'account',
    path: '/account-settings/account',
    component: Account,
  },
  {
    name: 'preferences',
    path: '/project-settings/preferences',
    component: Preferences,
  },
  {
    name: 'subscriptions',
    path: '/account-settings/subscriptions',
    component: Subscriptions,
  },
  {
    name: 'billing',
    path: '/account-settings/billing',
    component: Billing,
  },
  {
    name: 'logs',
    path: '/account-settings/logs',
    component: Logs,
  },
  {
    name: 'stripe-checkout-redirect',
    path: '/project-settings/stripe-checkout-redirect',
    component: StripeCheckoutRedirect,
  },
  {
    name: 'account-users',
    path: '/account-settings/account-users',
    component: AccountUsers,
  },
  {
    name: 'account-user-detail',
    path: '/account-settings/account-users/:id',
    component: AccountUserDetail,
  },
  {
    name: 'project-users',
    path: '/project-settings/project-users',
    component: ProjectUsers,
  },
  {
    name: 'project-user-detail',
    path: '/project-settings/project-users/:id',
    component: ProjectUserDetail,
  },
  {
    name: 'projects',
    path: '/account-settings/projects',
    component: Projects,
  },
  {
    name: 'project-detail',
    path: '/account-settings/projects/:id',
    component: ProjectDetail,
  },
  {
    name: 'reports',
    path: '/reports',
    component: Reports,
  },
  {
    name: 'energyReport',
    path: '/reports/energyReport',
    component: EnergyReport,
  },
  {
    name: 'portfolioReport',
    path: '/reports/portfolioReport',
    component: PortfolioReport,
  },
  {
    name: 'platformSummaryReport',
    path: '/reports/platformSummaryReport',
    component: PlatformSummaryReport,
  },
  {
    name: 'temperatureReport',
    path: '/reports/temperatureReport',
    component: TemperatureReport,
  },
  {
    name: 'currentReport',
    path: '/reports/currentReport',
    component: CurrentReport,
  },
  {
    name: 'carbonDioxideReport',
    path: '/reports/carbonDioxideReport',
    component: CarbonDioxideReport,
  },
  {
    name: 'humidityReport',
    path: '/reports/humidityReport',
    component: HumidityReport,
  },
  {
    name: 'airQualityReport',
    path: '/reports/airQualityReport',
    component: AirQualityReport,
  },
  {
    name: 'doorReport',
    path: '/reports/doorReport',
    component: DoorReport,
  },
  {
    name: 'interruptReport',
    path: '/reports/interruptReport',
    component: InterruptReport,
  },
  {
    name: 'electricityReport',
    path: '/reports/electricityReport',
    component: ElectricityReport,
  },
  {
    name: 'gasReport',
    path: '/reports/gasReport',
    component: GasReport,
  },
  {
    name: 'trafficReport',
    path: '/reports/trafficReport',
    component: TrafficReport,
  },
  {
    name: 'trafficVersusDoorReport',
    path: '/reports/trafficVersusDoorReport',
    component: TrafficVersusDoorReport,
  },
  {
    name: 'temperatureVersusDoorReport',
    path: '/reports/temperatureVersusDoorReport',
    component: TemperatureVersusDoorReport,
  },
  {
    name: 'temperatureVersusCurrentReport',
    path: '/reports/temperatureVersusCurrentReport',
    component: TemperatureVersusCurrentReport,
  },
  {
    name: 'batteryReport',
    path: '/reports/batteryReport',
    component: BatteryReport,
  },
  {
    name: 'waterVolumeReport',
    path: '/reports/waterVolumeReport',
    component: WaterVolumeReport,
  },
  {
    name: 'mapReport',
    path: '/reports/mapReport',
    component: MapReport,
  },
  {
    name: 'weeklyReport',
    path: '/reports/weeklyReport',
    component: WeeklyReport,
  },
  {
    name: 'routeReport',
    path: '/reports/routeReport',
    component: RouteReport,
  },
  {
    name: 'conversionReport',
    path: '/reports/conversionReport',
    component: ConversionReport,
  },

  {
    name: 'dataReport',
    path: '/reports/dataReport',
    component: DataReport,
  },
  {
    name: 'top10TrafficReport',
    path: '/reports/top10TrafficReport',
    component: Top10TrafficReport,
  },

  // { path: '/:notFound(.*)', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    // console.log('scrollBehavior()');
    // console.log(to);
    // console.log(from);
    return { top: 0 };
  },
});

router.beforeEach(function (to, from, next) {
  // store.commit({
  //   type: 'updateUserAlert',
  //   visible: false,
  // });
  next();
});

export default router;
