<template>
  <div class="py-6 px-6">
    <div class="max-w-xl text-xl pb-5">Portfolio Comparison Report - 2022</div>

    <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-10">
      <div class="py-2 px-1 mx-0 rounded" v-if="show_comparison_table">
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="py-2 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-8"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Building Name
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      kWh/M<sup>2</sup>
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      €/M<sup>2</sup>
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      CO<sub>2</sub>/M<sup>2</sup>
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      €/kWh
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      kWh
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      €
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      CO<sub>2</sub>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(building, buildingIdx) in buildings"
                    :key="building.name"
                    :class="[buildingIdx % 2 === 0 ? 'bg-white' : 'bg-white']"
                  >
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-medium text-gray-900"
                    >
                      <router-link :to="'/buildings/' + building._id">{{
                        building.name
                      }}</router-link>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="flex">
                        <p class="text-lg font-semibold text-gray-900">
                          {{
                            abbreviateNumber(
                              building.running_total_kwh_2022 /
                                building.square_meters
                            )
                          }}
                        </p>
                        <p
                          class="text-grey-600 ml-2 flex items-baseline text-xs"
                        >
                          <ArrowUpIcon
                            v-if="
                              building.running_total_co2_2022 >
                              building.running_total_co2_2021
                            "
                            class="h-4 w-4 flex-shrink-0 self-center text-red-300"
                            aria-hidden="true"
                          />
                          <ArrowDownIcon
                            v-else
                            class="h-4 w-4 flex-shrink-0 self-center text-green-300"
                            aria-hidden="true"
                          />

                          {{
                            abbreviateNumber(
                              building.running_total_kwh_2022 /
                                building.square_meters -
                                building.running_total_kwh_2021 /
                                  building.square_meters
                            )
                          }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="flex">
                        <p class="text-lg font-semibold text-gray-900">
                          {{
                            abbreviateNumber(
                              building.running_total_cost_2022 /
                                building.square_meters
                            )
                          }}
                        </p>
                        <p
                          class="text-grey-600 ml-2 flex items-baseline text-xs"
                        >
                          <ArrowUpIcon
                            v-if="
                              building.running_total_cost_2022 >
                              building.running_total_cost_2021
                            "
                            class="h-4 w-4 flex-shrink-0 self-center text-red-300"
                            aria-hidden="true"
                          />
                          <ArrowDownIcon
                            v-else
                            class="h-4 w-4 flex-shrink-0 self-center text-green-300"
                            aria-hidden="true"
                          />

                          {{
                            abbreviateNumber(
                              building.running_total_cost_2022 /
                                building.square_meters -
                                building.running_total_cost_2021 /
                                  building.square_meters
                            )
                          }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="flex">
                        <p class="text-lg font-semibold text-gray-900">
                          {{
                            abbreviateNumber(
                              building.running_total_co2_2022 /
                                building.square_meters
                            )
                          }}
                        </p>
                        <p
                          class="text-grey-600 ml-2 flex items-baseline text-xs"
                        >
                          <ArrowUpIcon
                            v-if="
                              building.running_total_co2_2022 >
                              building.running_total_co2_2021
                            "
                            class="h-4 w-4 flex-shrink-0 self-center text-red-300"
                            aria-hidden="true"
                          />
                          <ArrowDownIcon
                            v-else
                            class="h-4 w-4 flex-shrink-0 self-center text-green-300"
                            aria-hidden="true"
                          />

                          {{
                            abbreviateWeight(
                              building.running_total_co2_2022 /
                                building.square_meters -
                                building.running_total_co2_2021 /
                                  building.square_meters
                            )
                          }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="flex">
                        <p class="text-lg font-semibold text-gray-900">
                          {{
                            (
                              building.running_total_cost_2022 /
                              building.running_total_kwh_2022
                            ).toFixed(2)
                          }}
                        </p>
                        <p
                          class="text-grey-600 ml-2 flex items-baseline text-xs"
                        ></p>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="flex">
                        <p class="text-lg font-semibold text-gray-900">
                          {{
                            abbreviateNumber(building.running_total_kwh_2022)
                          }}
                        </p>
                        <p
                          class="text-grey-600 ml-2 flex items-baseline text-xs"
                        >
                          <ArrowUpIcon
                            v-if="
                              building.running_total_co2_2022 >
                              building.running_total_co2_2021
                            "
                            class="h-4 w-4 flex-shrink-0 self-center text-red-300"
                            aria-hidden="true"
                          />
                          <ArrowDownIcon
                            v-else
                            class="h-4 w-4 flex-shrink-0 self-center text-green-300"
                            aria-hidden="true"
                          />

                          {{
                            abbreviateNumber(
                              building.running_total_kwh_2022 -
                                building.running_total_kwh_2021
                            )
                          }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="flex">
                        <p class="text-lg font-semibold text-gray-900">
                          {{
                            abbreviateNumber(building.running_total_cost_2022)
                          }}
                        </p>
                        <p
                          class="text-grey-600 ml-2 flex items-baseline text-xs"
                        >
                          <ArrowUpIcon
                            v-if="
                              building.running_total_cost_2022 >
                              building.running_total_cost_2021
                            "
                            class="h-4 w-4 flex-shrink-0 self-center text-red-300"
                            aria-hidden="true"
                          />
                          <ArrowDownIcon
                            v-else
                            class="h-4 w-4 flex-shrink-0 self-center text-green-300"
                            aria-hidden="true"
                          />

                          {{
                            abbreviateNumber(
                              building.running_total_cost_2022 -
                                building.running_total_cost_2021
                            )
                          }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="flex">
                        <p class="text-lg font-semibold text-gray-900">
                          {{
                            abbreviateWeight(building.running_total_co2_2022)
                          }}
                        </p>
                        <p
                          class="text-grey-600 ml-2 flex items-baseline text-xs"
                        >
                          <ArrowUpIcon
                            v-if="
                              building.running_total_co2_2022 >
                              building.running_total_co2_2021
                            "
                            class="h-4 w-4 flex-shrink-0 self-center text-red-300"
                            aria-hidden="true"
                          />
                          <ArrowDownIcon
                            v-else
                            class="h-4 w-4 flex-shrink-0 self-center text-green-300"
                            aria-hidden="true"
                          />

                          {{
                            abbreviateWeight(
                              building.running_total_co2_2022 -
                                building.running_total_co2_2021
                            )
                          }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- /End replace -->
      </div>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div
        v-for="chart in this.charts"
        :key="chart.options"
        class="px-4 py-5 sm:px-6"
      >
        <apexchart
          width="100%"
          height="300"
          :options="chart.options"
          :series="chart.series"
        ></apexchart>
      </div>

      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Switch } from '@headlessui/vue';

import moment from 'moment';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
  DocumentDownloadIcon,
  UsersIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    ArrowDownIcon,
    ArrowUpIcon,
  },

  data() {
    return {
      building: {
        chart: null,
        meters: [],
        series_data: [],
      },
      serverResponse: '',
      streamNameValidationError: false,
      userAlertBottom: {
        status: '',
        message: '',
      },

      //temp_map: new Map(),
      //master_date_list: [],
      buildings: [],
      charts: [],
      co2_emission_factor_gas: 202.9,
      co2_emission_factor_electricity: 347.8,
      show_comparison_table: false,
    };
  },

  methods: {
    validateStreamName(event) {
      const regex = /^[a-z0-9-]+$/;
      if (regex.test(event.target.value)) {
        this.streamNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.streamNameValidationError = true;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YY');
      }
    },
    abbreviateNumber(number) {
      // console.log('abbreviatingNumber: ' + number);
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) {
        // console.log('tier == 0');
        return number.toFixed(0);
      }

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;
      // console.log('returning: ' + scaled.toFixed(1) + suffix);

      // format number and add suffix
      return scaled.toFixed(0) + suffix;
    },
    abbreviateWeight(number) {
      // console.log('abbreviatingNumber: ' + number);
      const SI_SYMBOL = ['', 'KG', 'T'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) {
        // console.log('tier == 0');
        return number;
      }

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;
      // console.log('returning: ' + scaled.toFixed(1) + suffix);

      // format number and add suffix
      return scaled.toFixed(0) + suffix;
    },

    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      // console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },

    getMeterData(building) {
      console.log('getMeterData called for building: ' + building.name);
      let meter_count = building.meters.length;
      let count = 0;
      building.running_total_kwh_2021 = 0;
      building.running_total_cost_2021 = 0;
      building.running_total_co2_2021 = 0;
      building.running_total_kwh_2022 = 0;
      building.running_total_cost_2022 = 0;
      building.running_total_co2_2022 = 0;
      for (let meter of building.meters) {
        // console.log('calling: ' + `/meters/${meter.meter_id}`);
        axios
          .get(`/meters/${meter.meter_id}`)
          .then((res) => {
            count++;
            let data = [];
            let meter = res.data.data;
            // console.log('meter object');
            // console.log(meter);

            //Let's iterate through the reports and build the series
            for (let monthly_report of meter.consumption_reports) {
              data.push({
                x: monthly_report.start_date,
                y: Math.round(monthly_report.usage),
              });
              if (new Date(monthly_report.start_date).getFullYear() == 2021) {
                building.running_total_kwh_2021 =
                  building.running_total_kwh_2021 + monthly_report.usage;
                let monthly_cost = monthly_report.usage * meter.price_per_unit;
                building.running_total_cost_2021 =
                  building.running_total_cost_2021 + monthly_cost;

                let emission_factor = 0;
                if (meter.type == 'gas') {
                  emission_factor = this.co2_emission_factor_gas;
                } else if (meter.type == 'electricity') {
                  emission_factor = this.co2_emission_factor_electricity;
                }
                building.running_total_co2_2021 =
                  building.running_total_co2_2021 +
                  monthly_report.usage * emission_factor;
              } else if (
                new Date(monthly_report.start_date).getFullYear() == 2022
              ) {
                building.running_total_kwh_2022 =
                  building.running_total_kwh_2022 + monthly_report.usage;

                let monthly_cost = monthly_report.usage * meter.price_per_unit;
                building.running_total_cost_2022 =
                  building.running_total_cost_2022 + monthly_cost;

                let emission_factor = 0;
                if (meter.type == 'gas') {
                  emission_factor = this.co2_emission_factor_gas;
                } else if (meter.type == 'electricity') {
                  emission_factor = this.co2_emission_factor_electricity;
                }
                building.running_total_co2_2022 =
                  building.running_total_co2_2022 +
                  monthly_report.usage * emission_factor;
              }
            }

            if (building.series_data == null) {
              building.series_data = [];
            }

            building.series_data.push({
              name: meter.type,
              type: 'bar',
              data: data,
            });
            // console.log('adding series: ');
            // console.log(meter.type);

            if (count >= meter_count) {
              this.loadGraph(building);
              // console.log('loading graph of this building ....');
              // console.log(building);
              this.show_comparison_table = true;
            }
          })
          .catch();
      }
    },
    loadGraph(building) {
      // console.log('loadGraph() series_data:');
      // console.log(building.series_data);
      let series_type = 'electricty';
      let chart = {
        reference_number: 'reference_number',
        options: {
          theme: {
            palette: 'palette2',
          },

          chart: {
            stacked: false,
            type: 'bar',
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
          },
          stroke: {
            show: true,
            curve: ['smooth', 'smooth'],
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
          },
          title: {
            text: building.name,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238',
            },
          },
          subtitle: {
            text: building.description,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 20,
            floating: false,
            style: {
              fontSize: '12px',
              fontWeight: 'normal',
              fontFamily: undefined,
              color: '#9699a2',
            },
          },
          dataLabels: {
            enabled: false,
          },

          tooltip: {
            enabled: true,
            x: {
              show: true,
              format: 'MM yyyy',
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            autoSelected: 'zoom',
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: true,
              format: 'MM yy',
            },
          },
          yaxis: [
            {
              seriesName: 'electricity',
              show: true,
              title: {
                text: 'kwh',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
              },
              labels: {
                formatter: function (value) {
                  return value / 1000 + 'k';
                },
              },
            },
            {
              seriesName: 'electricity',
              show: false,
              title: {
                text: 'kwh',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
              },
            },
            // {
            //   seriesName: 'ambient temperature',
            //   opposite: true,
            //   title: {
            //     text: 'ambient temperature',
            //     rotate: 90,
            //     offsetX: 0,
            //     offsetY: 0,
            //   },
            // },
          ],
        },
        series: building.series_data,
      };
      this.charts.push(chart);
    },
  },

  created() {
    // console.log('created() called with: ' + this.$route);
    // console.log('this.$route.path:' + this.$route.path);

    // this.building.core_account_name = this.$store.state.clime_account.name;
    // this.building.core_project_name = this.$store.state.clime_project.name;

    const currentAccountSession = this.$store.state.clime_account.name;
    const currentProjectSession = this.$store.state.clime_project.name;

    axios
      .get(
        '/buildings?core_account_name=' +
          currentAccountSession +
          '&core_project_name=' +
          currentProjectSession
      )
      .then((res) => {
        this.serverResponse = JSON.stringify(res, null, 2);
        this.buildings = res.data.data;
        for (let building of this.buildings) {
          this.getMeterData(building);
        }

        // for (let building of this.buildings) {
        //   axios
        //     .get('/buildings/' + building._id)
        //     .then((res) => {
        //       this.getMeterData(res.data.data);
        //     })
        //     .catch((err) => {});
        // }
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
      });
  },
};
</script>
