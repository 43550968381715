<template>
  <div class="py-6 px-6">
    <!-- Replace with your content -->

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ gateway.core_gateway_id }}
        </h3>
        <h5 class="text-sm leading-6 font-medium text-gray-500">
          {{ gateway.description }}
        </h5>

        <div class="ml-0 mt-0">
          <div class="flex items-center mt-5 mb-2">
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="refresh"
            >
              Refresh
            </button>

            <div
              class="ml-5 px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full"
            >
              Last Seen
              {{
                getTimeDiff(
                  dateMath.diff(
                    new Date(gateway.last_seen),
                    new Date(),
                    'minutes',
                    false
                  )
                )
              }}
            </div>
          </div>
        </div>

        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Devices</h3>
          <p class="mt-1 text-sm text-gray-500">
            List of devices that have communicated through this gateway in the
            past 48 hours
          </p>
        </div>

        <div class="mb-4">
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div v-for="device in gateway.active_devices" :key="device.name">
              <gateway-stream-panel
                :name="device.core_device_id"
                :humidity="device.humidity"
                :counter="device.counter"
                :battery="device.battery"
                :door_open="device.door_open"
                :temperature="device.temperature"
                :temperature_probe="device.temperature_probe"
                :eco2="device.eco2"
                :tvoc="device.tvoc"
                :last_seen="device.last_seen"
                :product_name="device.product_name"
                :_id="device._id"
              ></gateway-stream-panel>
            </div>
          </dl>
        </div>

        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Gateway Details
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Edit and save changes to Gateway
          </p>
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Gateway Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="core_gateway_id"
                      id="core_gateway_id"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.core_gateway_id"
                      v-on:input="validateGatewayName"
                    />
                  </div>
                  <p
                    v-if="gatewayNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p>
                </div>
              </dd>
            </div>
            <div
              :class="[
                gateway.live ? 'bg-white' : 'bg-red-400',
                'px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6',
              ]"
            >
              <dt class="text-sm font-medium text-gray-500">Live</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                <Switch
                  v-model="gateway.live"
                  :class="[
                    gateway.live ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                  ]"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      gateway.live ? 'translate-x-5 ' : 'translate-x-0 ',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                    ]"
                  />
                </Switch>
              </dd>
              <dd class="sm:col-span-1">
                <span v-if="!gateway.live" class="text-sm text-white"
                  >If the gateway is no longer live, the SIM card is turned off
                  and any sensors associated with the gateway will no longer
                  transmit data regardless of their 'live' state
                </span>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Tags</dt>

              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <div class="flex">
                  <div class="mb-2 italic text-gray-500">
                    Input tags for &nbsp;
                  </div>
                  <div
                    v-for="group in account.account_tag_groups"
                    :key="group"
                    class="mb-2 italic text-gray-500"
                  >
                    {{ group.name }} ,
                  </div>
                </div>
                <div>
                  <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    :autocomplete-items="filteredItems"
                    @tags-changed="(newTags) => (tags = newTags)"
                  />
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">SIM Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="sim_status"
                      id="sim_status"
                      disabled
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="sim_status"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Gateway EUI</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_eui"
                      id="gateway_eui"
                      disabled
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_eui"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Serial Number</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_serial"
                      id="gateway_serial"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_serial"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Hostname</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_serial"
                      id="gateway_serial"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.hostname"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">SIM ICCID</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_sim_iccid"
                      id="gateway_sim_iccid"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.sim_iccid"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Gateway Description
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <textarea
                      rows="3"
                      name="description"
                      id="description"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.description"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <!-- Start Alerts component -->
            <div
              class="bg-gray-50 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Alerts</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                <div class="flex justify-end p-2 pr-5">
                  <button
                    type="button"
                    v-on:click="addAlert"
                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add Alert
                  </button>
                </div>
                <ul class="border=0 border-gray-200 rounded-md">
                  <!-- Alert list -->

                  <li
                    class="bg-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm border-2 border-white"
                    v-for="(alert, position) in gateway.alerts"
                    :key="position"
                  >
                    <!-- Start Alert (move to component in the future -->

                    <form class="space-y-8 divide-y divide-gray-200">
                      <div class="space-y-8 divide-y divide-gray-200">
                        <div class="pt-8">
                          <div class="sm:col-span-2 justify-self-center">
                            <p>Active</p>
                            <Switch
                              v-model="alert.active"
                              :class="[
                                alert.active ? 'bg-indigo-600' : 'bg-white',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                              ]"
                            >
                              <span class="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                :class="[
                                  alert.active
                                    ? 'translate-x-5 '
                                    : 'translate-x-0 ',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                                ]"
                              />
                            </Switch>
                          </div>

                          <div
                            class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                          >
                            <div class="sm:col-span-6">
                              <label
                                for="street-address"
                                class="block text-sm font-medium text-gray-700"
                              >
                                Alert Name
                              </label>
                              <div class="mt-1">
                                <input
                                  type="text"
                                  v-model="alert.name"
                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pt-8"
                        >
                          <div class="sm:col-span-6">
                            <h3
                              class="text-lg leading-6 font-medium text-gray-900"
                            >
                              Notification Channels
                            </h3>
                            <p class="mt-1 text-sm text-gray-500">
                              Indicate how you would like to be notified. We
                              integrate with PagerDuty for escalations - please
                              click here for more information.
                            </p>
                          </div>

                          <div class="sm:col-span-4 sm:col-start-1">
                            <div class="mt-0">
                              <label
                                class="block text-sm font-medium text-gray-700"
                              >
                                Select Esclation Channel
                              </label>
                              <div class="mt-1">
                                <select
                                  v-model="alert.escalation_channel"
                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                >
                                  <option
                                    v-for="channel in escalation_channels"
                                    :key="channel.name"
                                    :value="{
                                      provider: channel.provider,
                                      name: channel.name,
                                      api_key: channel.api_key,
                                      routing_key: channel.routing_key,
                                      email_notification:
                                        channel.email_notification,
                                    }"
                                  >
                                    [{{ channel.provider }}]
                                    {{ channel.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="pt-5">
                        <div class="flex justify-end">
                          <div class="justify-self-end">
                            <button
                              type="button"
                              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              v-on:click="deleteAlert(position)"
                            >
                              Delete Alert
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <!--  End of Alert component  -->
                  </li>
                </ul>
              </dd>
            </div>
            <!-- Finish Alerts component  -->

            <!-- Start map component   -->

            <div class="bg-gray-50 mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
              >
                <label
                  class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 pl-5"
                >
                  Current Location
                </label>

                <div class="mt-1 flex rounded-md shadow-sm">
                  <span
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                    ]"
                    @click="useCurrentLocation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="geoMessage"
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                    ]"
                  />

                  <div class="ml-4 mt-2">
                    <p class="text-sm text-gray-500">
                      <RefreshIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                        @click="refreshLocation"
                      />
                    </p>
                  </div>
                </div>

                <div
                  class="mt-2 sm:col-span-1 align-middle justify-self-center"
                >
                  <Switch
                    v-model="storeLocation"
                    :class="[
                      storeLocation ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                    ]"
                  >
                    <span class="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        storeLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                      ]"
                    />
                  </Switch>
                </div>
              </div>
              <div
                v-if="gateway.gps_enabled"
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
              >
                <label
                  class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 pl-5"
                >
                  Trip Report
                </label>
                <Datepicker
                  class="col-span-2 pr-5"
                  v-model="date"
                  range
                  @update:modelValue="handleDate"
                />
              </div>

              <div v-if="route_map" class="sm:col-span-3 px-2">
                <div
                  class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          trip
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          start date
                        </th>
                        <th
                          scope="col"
                          class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          finish date
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          distance (km)
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          duration (mins)
                        </th>

                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          top speed (km/h)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(trip, tripIdx) in trips"
                        :key="trip"
                        :class="tripIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      >
                        <td
                          class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                        >
                          <div class="flex">
                            <div class="grid justify-items-center h-5 ml-5">
                              <input
                                type="checkbox"
                                class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-white"
                                :value="trip.index"
                                v-model="checked_trips"
                              />
                            </div>
                          </div>
                        </td>

                        <td
                          class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                        >
                          {{ trip.start_date.toLocaleString() }}
                        </td>

                        <td
                          class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                        >
                          {{ trip.finish_date.toLocaleString() }}
                        </td>

                        <td
                          class="px-6 py-4 whitespace-nowrap text-xs text-gray-500"
                        >
                          {{ trip.total_trip_distance }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-xs text-gray-500"
                        >
                          {{ trip.total_trip_time }}
                        </td>

                        <td
                          class="px-6 py-4 whitespace-nowrap text-xs text-gray-500"
                        >
                          {{ trip.local_top_speed.toFixed(0) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="route_map" class="sm:col-span-3 p-5">
                <div class="">
                  <fieldset class="mt-0">
                    <div class="sm:grid grid-cols-4 grid-cols-1">
                      <div class="flex items-center">
                        <input
                          id="speed_all"
                          value="ZERO"
                          name="speed_segment"
                          type="radio"
                          v-model="speed_segment"
                          checked
                          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          for="speed_all"
                          class="ml-3 block text-sm font-medium text-gray-700"
                        >
                          All Speed Segments
                        </label>
                      </div>

                      <div class="flex items-center">
                        <input
                          id="speed_above_50"
                          value="FIFTY"
                          name="speed_segment"
                          type="radio"
                          v-model="speed_segment"
                          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          for="speed_above_50"
                          class="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Between 50km/h and 100km/h
                        </label>
                      </div>

                      <div class="flex items-center">
                        <input
                          id="speed_above_100"
                          value="ONE_HUNDRED"
                          name="speed_segment"
                          type="radio"
                          v-model="speed_segment"
                          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          for="speed_above_100"
                          class="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Between 100km/h and 120km/h
                        </label>
                      </div>
                      <div class="flex items-center">
                        <input
                          id="speed_above_120"
                          value="ONE_HUNDRED_AND_TWENTY"
                          name="speed_segment"
                          type="radio"
                          v-model="speed_segment"
                          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          for="speed_above_120"
                          class="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Above 120km/h
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div
                class="mt-2 sm:col-span-3 align-middle justify-self-center border"
              >
                <GoogleMap
                  :api-key="googleMapsAPIKey"
                  style="width: 100%; height: 500px"
                  :center="center"
                  :zoom="12"
                  v-if="showMap && storeLocation"
                >
                  <Marker v-if="!route_map" :options="{ position: center }" />
                  <div v-else>
                    <div v-if="speed_segment == 'ZERO'">
                      <div v-for="trip in trips" :key="trip">
                        <Polyline
                          v-if="checked_trips.includes(trip.index)"
                          :options="trip.route"
                        />
                      </div>
                    </div>
                    <div v-else-if="speed_segment == 'FIFTY'">
                      <div v-for="route in gatewayRoute50" :key="route">
                        <Polyline :options="route" />
                      </div>
                    </div>
                    <div v-else-if="speed_segment == 'ONE_HUNDRED'">
                      <div v-for="route in gatewayRoute100" :key="route">
                        <Polyline :options="route" />
                      </div>
                    </div>
                    <div v-else-if="speed_segment == 'ONE_HUNDRED_AND_TWENTY'">
                      <div v-for="route in gatewayRoute120" :key="route">
                        <Polyline :options="route" />
                      </div>
                    </div>
                  </div>
                </GoogleMap>
              </div>
              <div></div>
            </div>
            <!-- End Map component -->
          </dl>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <!-- <button
              type="button"
              v-on:click="confirmRemoveGateway = true"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete Gateway
            </button> -->
            <feedback-button
              v-if="deleteButtonState == 'none'"
              v-on:click="confirmRemoveGateway = true"
              :text="'Delete Gateway'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'success'"
              :state="'success'"
              :text="'Deleted!'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'error'"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>

            <confirm-action
              v-if="confirmRemoveGateway"
              :title_text="'Delete Gateway'"
              :message_text="'Are you sure you want to Delete this Gateway? This action cannot be undone!'"
              :confirm_button_text="'Delete Gateway'"
              @userAction="removeGateway($event)"
            ></confirm-action>

            <feedback-button
              v-if="submitButtonState == 'none'"
              v-on:click="updateGateway"
              :text="'Submit'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'error'"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>

            <!-- <button
              type="submit"
              v-on:click="updateGateway"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button> -->
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { Switch } from '@headlessui/vue';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import { GoogleMap, Marker, Polyline } from 'vue3-google-map';
import LoadingWidget from '../components/LoadingWidget.vue';
import GatewayStreamPanel from '../components/GatewayStreamPanel.vue';
import FeedbackButton from '../components/FeedbackButton.vue';
import ConfirmAction from '../components/ConfirmAction.vue';
import VueTagsInput from '@sipec/vue3-tags-input';
import Datepicker from '@vuepic/vue-datepicker';
import '../date_picker.css';
import { ref, onMounted } from 'vue';
import encodeurl from 'encodeurl';
import { getDistance } from 'geolib';

const ROUTE_COLORS = [
  '#0f172a',
  '#854d0e',
  '#dc2626',
  '#881337',
  '#1d4ed8',
  '#8b5cf6',
  '#701a75',
  '#f472b6',
  '#6366f1',
  '#0ea5e9',
];

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    ServerResponse,
    AlertBanner,
    GoogleMap,
    Marker,
    Polyline,
    LoadingWidget,
    GatewayStreamPanel,
    FeedbackButton,
    ConfirmAction,
    VueTagsInput,
    Datepicker,
  },

  setup() {
    const date = ref();
    // For demo purposes assign range from the current date
    onMounted(() => {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      date.value = [startDate, endDate];
    });

    return {
      date,
    };
  },

  data() {
    return {
      gateway: {
        core_gateway_id: '',
        gateway_eui: '',
        gateway_serial: '',
        core_account_name: '',
        attributes: {
          core_model: 'core_gateway_01',
        },
        latitude: 0,
        longitude: 0,
        sim_eid: '',
        sim_iccid: '',
        live: true,
        active_devices: [],
        last_billed: '',
        hostname: '',
        date: null,
      },
      dateMath,
      serverResponse: '',
      gatewayNameValidationError: false,
      // addingGateway: false,
      geoMessage: '',
      storeLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      sim_status: '',
      googleMapsAPIKey: '',
      showLoading: false,
      escalation_channels: [],
      device_list: [],
      account: {},
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      confirmRemoveGateway: false,
      confirmUserAction: false,
      tag: '',
      tags: [],
      autocompleteItems: [],
      route_map: false,
      gatewayRoute: [],
      gatewayRoute50: [],
      gatewayRoute100: [],
      gatewayRoute120: [],
      speed_segment: 'ZERO',
      trips: [],
      checked_trips: [],
    };
  },

  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },

  methods: {
    handleDate() {
      console.log('select clicked!');
      console.log(this.date);

      this.trips = [];

      let start_date = new Date(this.date[0]);
      let end_date = new Date(this.date[1]);

      let timestream_start_date = start_date
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '');
      let timestream_end_date = end_date
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '');

      console.log(timestream_start_date);
      console.log(timestream_end_date);

      let query_string = `SELECT * FROM "core_timestream_prod"."gateway_locations_v1" WHERE gateway_id = '${this.gateway.core_gateway_id}' and time between TIMESTAMP '${timestream_start_date}' and TIMESTAMP '${timestream_end_date}' ORDER BY time ASC`;
      axios
        .post('/reports/query', { query_string: query_string })
        .then((res) => {
          console.log(res);
          let trip_coordinates = [];
          let trip = {};
          let first_coordinate = true;
          let start_date = null;
          let finish_date = null;
          let total_trip_distance = 0;
          let local_top_speed = 0;
          let distance = 0;
          let speed = 0;
          let is_final_coordinate = false;
          let trip_index = 0;

          for (let i = 0; i < res.data.result.length; i++) {
            if (i == res.data.result.length - 1) {
              is_final_coordinate = true;
            }

            let result = res.data.result[i];

            trip_coordinates.push({
              lat: parseFloat(result.latitude),
              lng: parseFloat(result.longitude),
            });

            if (!first_coordinate) {
              //Reformatting timestream timestamp
              let date_current_coordinate = new Date(
                res.data.result[i].time.replace(' ', 'T')
              );
              finish_date = date_current_coordinate;
              let date_previous_coordinate = new Date(
                res.data.result[i - 1].time.replace(' ', 'T')
              );

              let seconds_between_coordinates =
                (date_current_coordinate - date_previous_coordinate) / 1000;

              let seconds_to_next_coordinate = 0;

              if (!is_final_coordinate) {
                let date_next_coordinate = new Date(
                  res.data.result[i + 1].time.replace(' ', 'T')
                );

                seconds_to_next_coordinate =
                  (date_next_coordinate - date_current_coordinate) / 1000;
              }

              //If the time between co-ordinates is greater than say 15 mins, then we know
              //we are into a different trip. We will always only show the final trip of the
              //timeframe, or in future - record all distinct trips separately
              if (seconds_to_next_coordinate > 60 * 15 || is_final_coordinate) {
                //Let's close off this first trip and move onto the next
                trip_index++;
                trip.start_date = start_date;
                trip.finish_date = finish_date;
                trip.total_trip_distance = (total_trip_distance / 1000).toFixed(
                  0
                );
                trip.total_trip_time = (
                  (finish_date - start_date) /
                  (1000 * 60)
                ).toFixed(0);

                trip.local_top_speed = local_top_speed;
                trip.route = {
                  path: trip_coordinates,
                  geodesic: true,
                  strokeColor: ROUTE_COLORS[Math.floor(Math.random() * 10)],
                  strokeOpacity: 1,
                  strokeWeight: 3,
                };
                trip.index = trip_index;
                this.checked_trips.push(trip.index);
                this.trips.push(trip);
                console.log('Finishing One Trip: ');
                console.log(trip);

                first_coordinate = true;
                trip_coordinates = [];
                trip = {};
                start_date = null;
                finish_date = null;
                total_trip_distance = 0;
                local_top_speed = 0;
              } else {
                let local_coordinates = [];
                local_coordinates.push({
                  lat: parseFloat(res.data.result[i - 1].latitude),
                  lng: parseFloat(res.data.result[i - 1].longitude),
                });

                local_coordinates.push({
                  lat: parseFloat(res.data.result[i].latitude),
                  lng: parseFloat(res.data.result[i].longitude),
                });

                distance = getDistance(
                  {
                    latitude: res.data.result[i].latitude,
                    longitude: res.data.result[i].longitude,
                  },
                  {
                    latitude: res.data.result[i - 1].latitude,
                    longitude: res.data.result[i - 1].longitude,
                  }
                );

                //Let's increment the total distance traveled
                total_trip_distance += distance;

                speed =
                  distance / 1000 / (seconds_between_coordinates / (60 * 60));
                //console.log(`speed: ${speed} kph`);
                if (speed > local_top_speed) {
                  local_top_speed = speed;
                }

                if (speed > 120) {
                  this.gatewayRoute120.push({
                    path: local_coordinates,
                    geodesic: true,
                    strokeColor: '#dc2626',
                    strokeOpacity: 0.75,
                    strokeWeight: 5,
                  });
                } else if (speed > 100) {
                  this.gatewayRoute100.push({
                    path: local_coordinates,
                    geodesic: true,
                    strokeColor: '#f97316',
                    strokeOpacity: 0.75,
                    strokeWeight: 5,
                  });
                } else if (speed > 50) {
                  this.gatewayRoute50.push({
                    path: local_coordinates,
                    geodesic: true,
                    strokeColor: '#16a34a',
                    strokeOpacity: 0.75,
                    strokeWeight: 5,
                  });
                }
              }
            } else {
              start_date = new Date(res.data.result[i].time.replace(' ', 'T'));
              first_coordinate = false;
            }
          }
          this.route_map = true;
        })
        .catch();
    },
    addAlert() {
      this.gateway.alerts.unshift({
        name: 'New alert name',
        active: false,
        escalation: {
          provider: 'pager duty',
          path: { name: '', key: '' },
        },
      });
    },
    deleteAlert(position) {
      console.log('Deleting Alert from position ' + position);
      this.gateway.alerts.splice(position, 1);
    },
    validateGatewayName(event) {
      let updatedName = event.target.value;
      updatedName = updatedName.toLowerCase();

      const regex = /^[a-z0-9-]+$/;
      if (regex.test(updatedName)) {
        this.gatewayNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.gatewayNameValidationError = true;
      }

      this.gateway.core_gateway_id = updatedName;
    },

    updateGateway() {
      this.submitButtonState = 'loading';
      //Let's set the location data on the device
      if (this.storeLocation) {
        this.gateway.latitude = this.center.lat;
        this.gateway.longitude = this.center.lng;
      }

      //Let's update the tags if they have been modified
      this.gateway.tags = [];
      for (const tag_element of this.tags) {
        this.gateway.tags.push(tag_element.text);
      }

      axios
        .put(
          '/gateways/' +
            this.$route.params.id +
            '?user_email=' +
            this.$auth.user.value.email,
          this.gateway
        )
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    removeGateway(userConfirmed) {
      console.log('removeDevice() called with userConfirmed: ' + userConfirmed);
      this.confirmRemoveGateway = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';

        //We will only ever be updating the gateway, not actually deleting it
        this.gateway.install = false;
        axios
          .put(
            '/gateways/' +
              this.$route.params.id +
              '?user_email=' +
              this.$auth.user.value.email,
            this.gateway
          )
          .then((res) => {
            this.serverResponse = JSON.stringify(res, null, 2);
            this.raiseSuccessAlert('Successfully removed your gateway');
            this.deleteButtonState = 'success';
            setTimeout(() => {
              (this.deleteButtonState = 'none'), this.$router.push('/gateways');
            }, 2000);
          })
          .catch((err) => {
            this.serverResponse = JSON.stringify(err.response, null, 2);
            this.raiseErrorAlert(err);
            this.deleteButtonState = 'error';
            setTimeout(() => {
              this.deleteButtonState = 'none';
            }, 5000);
          });
      }
    },

    getSIMStatus(iccid) {
      const headers = {
        Authorization: process.env.VUE_APP_CONNECTED_YOU_SIM_API_KEY,
      };

      axios
        .get('/proxy/sim-status?gateway_iccid=' + iccid, { headers })
        .then((res) => {
          this.sim_status = res.data.data.status;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.sim_status = 'processing...';
          //this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    refresh() {
      this.showLoading = true;
      console.log(
        'Gateway refresh URL: ' +
          process.env.VUE_APP_TTI_GATEWAY_URL +
          this.gateway.tti_gateway_id +
          process.env.VUE_APP_TTI_GATEWAY_REQUEST
      );

      axios
        .get(
          process.env.VUE_APP_TTI_GATEWAY_URL +
            this.gateway.tti_gateway_id +
            process.env.VUE_APP_TTI_GATEWAY_REQUEST,
          {
            headers: {
              authorization: `Bearer ${process.env.VUE_APP_TTI_GATEWAY_AUTHORIZATION}`,
            },
          }
        )
        .then((res) => {
          console.log('success with axios request');
          console.log(res.data);

          //Update this code to increment Stripe billing if this is the first time this
          //device has been seen this month. If we don't do this here, then the database will
          //be updated with this month's data point and the gateway cron will not see it as a
          //new gateway. This normall happens at install time
          let previous_last_seen_date = null;
          let now = new Date();

          if (this.gateway.last_seen != null) {
            previous_last_seen_date = new Date(this.gateway.last_seen);
          }

          //Now that we have the previous last_seen recorded, let's get the latest last_seen
          //and compare
          //However, let's only update the value if it's not null. This will prevent the new date
          //going to null when gateways are down, and then incrementing billing when they come back online
          if (res.data.last_status_received_at != null) {
            this.gateway.last_seen = res.data.last_status_received_at;
          }

          //If the previous_last_seen_date is null, then we are installing this for the first time
          console.log('previous_last_seen_date: ' + previous_last_seen_date);
          if (previous_last_seen_date != null) {
            console.log(
              'previous_last_seen_date.getMonth(): ' +
                previous_last_seen_date.getMonth()
            );
          }

          let antenna_locations = [{}];

          if (res.data.last_status.antenna_locations != null) {
            antenna_locations = res.data.last_status.antenna_locations;
          }
          console.log(`antenna_locations:`);
          console.log(antenna_locations);

          for (let location of antenna_locations) {
            if (location.source == 'SOURCE_GPS') {
              this.center.lat = location.latitude;
              this.center.lng = location.longitude;
              console.log(
                `Found GPS co-ordinates: lat[${this.center.lat}] lon[${this.center.lng}] `
              );
              this.geoMessage = this.center.lat + ',' + this.center.lng;
            }
          }

          //Let's update the database with the latest inforamtion
          this.updateGateway();
          this.refreshLocation();

          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        })
        .catch((err) => {
          console.log('error caught witih axios request');
          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        });
    },

    useCurrentLocation() {
      if (!navigator.geolocation) {
        this.geoMessage = 'Geolocation is not supported by your browser';
      } else {
        this.geoMessage = 'Locating…';
        navigator.geolocation.getCurrentPosition(
          this.geoSuccess,
          this.geoError
        );
      }
    },
    geoSuccess(position) {
      this.latitude = position.coords.latitude.toFixed(2);
      this.longitude = position.coords.longitude.toFixed(2);
      this.geoMessage = this.latitude + ', ' + this.longitude;

      this.center.lat = position.coords.latitude;
      this.center.lng = position.coords.longitude;
      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },

    geoError() {
      this.geoMessage = 'Error determining location';
    },
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },

    refreshLocation() {
      this.route_map = false;
      var coords = this.geoMessage.split(',');
      this.center.lat = parseFloat(coords[0]);
      this.center.lng = parseFloat(coords[1]);

      console.log(
        'refresh location called - geoMessage: ' +
          this.center.lat +
          ':' +
          this.center.lng
      );

      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },

  created() {
    console.log('created() called with: ' + this.$route);
    console.log('this.$route.path:' + this.$route.path);

    this.googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    console.log(
      'process.env.VUE_APP_GOOGLE_MAPS_API_KEY: ' +
        process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    );
    console.log(
      'process.env.VUE_APP_TTI_GATEWAY_URL: ' +
        process.env.VUE_APP_TTI_GATEWAY_URL
    );

    axios
      .get('/gateways/' + this.$route.params.id)
      .then((res) => {
        console.log(res.data);
        this.gateway = res.data.data;
        this.center.lat = this.gateway.latitude;
        this.center.lng = this.gateway.longitude;
        this.geoMessage = this.center.lat + ',' + this.center.lng;
        this.showMap = true;
        this.getSIMStatus(this.gateway.sim_iccid);
        this.serverResponse = JSON.stringify(res, null, 2);

        axios
          .get('/accounts?account_name=' + this.gateway.core_account_name)
          .then((res) => {
            this.account = res.data.data[0];
            for (let i in this.account.escalation_channel) {
              this.escalation_channels.push(this.account.escalation_channel[i]);
            }

            for (const tag_group of this.account.account_tag_groups) {
              console.log('account_tag.group.name: ' + tag_group.name);

              for (const tag of tag_group.tags) {
                let tag_element = {
                  text: tag,
                  style:
                    'color: #000000; background-color: ' +
                    tag_group.color +
                    '; border: 0px',
                };
                this.autocompleteItems.push(tag_element);
                console.log('tag: ');
                console.log(tag);
                console.log('this.gateway.tags: ');
                console.log(this.gateway.tags);

                for (const gateway_tag of this.gateway.tags) {
                  if (gateway_tag == tag) {
                    this.tags.push(tag_element);
                  }
                }
              }
            }

            //We have to double check any remaining tags at the device level to see if they're
            //part of the official account tags or not. If not, then we highlight them with a different
            //color scheme (transparent background)

            for (const device_tag of this.device.tags) {
              let found_official_tag = false;

              for (const tag_group of this.account.account_tag_groups) {
                for (const official_tag of tag_group.tags) {
                  if (device_tag == official_tag) {
                    found_official_tag = true;
                  }
                }
              }

              //We have looped through all of the official tags. If we still didn't find it, then we
              //add it to the list as an unofficial tag with a transparent background
              if (!found_official_tag) {
                this.tags.push({
                  text: device_tag,
                  style:
                    'color: #000000; background-color: transparent; border: 1px solid #4F46E5',
                });
              }
            }

            console.log(this.autocompleteItems);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        console.log(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
      });
    //    }
  },
};
</script>
