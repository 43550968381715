<template>
  <div class="flex justify-start mr-5 mb-2 max-h-1">
    <Menu as="div" class="relative inline-block self-start ml-2 text-left w-48">
      <div>
        <MenuButton
          class="inline-flex justify-center w-full rounded-md border border-gray-200 shadow-sm ml-2 px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          Timeframe ({{ timeframe }})
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="z-40 origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <MenuItem
              v-for="time in timeframe_dropdown"
              :key="time"
              v-on:click="loadGraph(time), (this.timeframe = time)"
            >
              <div class="bg-white text-gray-900 block px-4 py-2 text-sm">
                {{ time }}
              </div>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>

  <div class="grid grid-cols-2 mb-10">
    <!-- first col for timeframe -->
    <div></div>
  </div>

  <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-center">
    <div><loading-widget /></div>
  </div>
  <div class="text-center mt-20" v-if="no_results">
    <h3 class="mt-2 text-sm font-medium text-gray-900">
      No Water Meter Devices matched your query
    </h3>
    <p class="mt-1 text-sm text-gray-500">
      Modify your query or create a new device
    </p>
    <div class="mt-6">
      <button
        type="button"
        v-on:click="openScan"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New Device
      </button>
    </div>
  </div>

  <div v-for="chart in dataset" :key="chart.core_device_id">
    <div class="filter drop-shadow-md mt-2 mb-2 border bg-white rounded-md">
      <apexchart
        width="100%"
        height="300"
        :options="chart.options"
        :series="chart.series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ServerResponse from './ServerResponse';
import LoadingWidget from './LoadingWidget.vue';
import { PlusIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { SearchIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';

export default {
  props: {
    core_device_id: { required: true, type: String },
  },
  components: {
    ServerResponse,
    LoadingWidget,
    PlusIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    SearchIcon,
    XIcon,
  },
  data() {
    return {
      dataset: [],
      loading: false,
      date: new Date(),
      no_results: false,
      timeframe: '3d',
      timeframe_dropdown: ['1h', '6h', '1d', '3d', '7d'],
    };
  },
  methods: {
    loadGraph(timeframe) {
      axios
        .get(
          '/reports/device?measure_name=water_volume&report_type=incremental&core_device_id=' +
            this.core_device_id +
            '&timeframe=' +
            timeframe
        )
        .then((res) => {
          let set = {
            core_device_id: this.core_device_id,
            options: {
              theme: {
                palette: 'palette2',
              },

              chart: {
                stacked: false,
                type: 'area',
                height: 350,
                zoom: {
                  type: 'x',
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: 'zoom',
                },
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 1,
                dashArray: 0,
              },
              fill: {
                colors: ['#0369a1', '#0369a1', '#0369a1'],
                opacity: undefined,
                type: 'gradient',
                gradient: {
                  shade: undefined,
                  type: 'vertical',
                  shadeIntensity: 0.5,
                  gradientToColors: undefined,
                  inverseColors: true,
                  opacityFrom: 0.5,
                  opacityTo: 0.8,
                  stops: [0, 50, 100],
                  colorStops: [],
                },
              },
              title: {
                text: this.core_device_id,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: undefined,
                  color: '#263238',
                },
              },
              subtitle: {
                text: 'description',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 20,
                floating: false,
                style: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontFamily: undefined,
                  color: '#9699a2',
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeFormatter: {
                    year: 'yyyy',
                    month: "MMM 'yy",
                    day: 'dd MMM',
                    hour: 'HH:mm',
                  },
                },
              },
              yaxis: [
                {
                  title: {
                    text: '',
                  },
                },
                {
                  opposite: true,
                  title: {
                    text: '',
                  },
                },
              ],
              tooltip: {
                enabled: true,
                x: {
                  show: true,
                  format: 'dd MMM HH:mm',
                },
              },
            },

            series: res.data.series,
          };
          this.dataset.push(set);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.loadGraph('3d');
    console.log(
      'created() called with core_device_id: [' + this.core_device_id + ']'
    );
  },
};
</script>
