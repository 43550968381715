<template>
  <div class="relative">
    <GoogleMap
      :api-key="googleMapsAPIKey"
      :style="{ width: '100%', height: '600px' }"
      :center="center"
      :zoom="2"
    >
      <Marker
        v-for:="gateway in gateways"
        :key="gateway.live"
        :options="{
          position: { lat: gateway.latitude, lng: gateway.longitude },
          icon: gateway.image,
        }"
        v-on:click="showTitle(gateway)"
      />
    </GoogleMap>
  </div>
  <server-response :response="serverResponse"></server-response>
  <div
    aria-live="assertive"
    class="absolute inset-0 flex items-start pointer-events-none p-30"
  >
    <div class="w-full flex flex-col items-end space-y-4 tems-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          v-bind:class="{
            'bg-white': !clickedGateway.alerting && clickedGateway.live,
            'bg-yellow-200': !clickedGateway.live,
            'bg-red-500': clickedGateway.alerting,
          }"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <WifiIcon
                  class="h-6 w-6"
                  v-bind:class="{
                    'text-indigo-700':
                      !clickedGateway.alerting && clickedGateway.live,
                    'text-white': clickedGateway.alerting,
                  }"
                  aria-hidden="true"
                />
              </div>
              <router-link
                :to="clickedGateway.route"
                class="ml-3 w-0 flex-1 pt-0.5"
              >
                <p
                  class="text-sm font-medium"
                  v-bind:class="{
                    'text-indigo-900':
                      !clickedGateway.alerting && clickedGateway.live,
                    'text-white': clickedGateway.alerting,
                  }"
                >
                  {{ clickedGateway.core_gateway_id }}
                </p>
                <div v-if="clickedGateway.live" class="mt-2">
                  <p
                    class="mb-2 whitespace-nowrap text-sm"
                    v-bind:class="{
                      'text-gray-500':
                        !clickedGateway.alerting && clickedGateway.live,
                      'text-white': clickedGateway.alerting,
                    }"
                  >
                    {{ clickedGateway.description }}
                  </p>
                  <span
                    class="px-2 py-1 text-green-800 text-xs font-medium rounded-full"
                    v-bind:class="{
                      'bg-white': clickedGateway.alerting,
                      'bg-green-100':
                        !clickedGateway.alerting && clickedGateway.live,
                    }"
                  >
                    Last Seen: {{ clickedGateway.time_diff }}
                  </span>
                </div>
                <div v-else>
                  <div class="mt-1 text-sm text-indigo-700">
                    Gateway is not live! Click here to turn back on
                  </div>
                </div>
              </router-link>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="show = false"
                  class="rounded-md inline-flex hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  v-bind:class="{
                    'text-indigo-700': !clickedGateway.alerting,
                    'text-white': clickedGateway.alerting,
                  }"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ServerResponse from '../../components/ServerResponse';
import axios from 'axios';
import { GoogleMap, Marker } from 'vue3-google-map';
import * as dateMath from 'date-arithmetic';
import { ref } from 'vue';
import { CheckCircleIcon, WifiIcon } from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';
import Vuex from 'vuex';
export default {
  setup() {
    const show = ref(false);
    // const store = Vuex.useStore();
    return {
      show,
    };
  },
  components: {
    ServerResponse,
    GoogleMap,
    Marker,
    CheckCircleIcon,
    XIcon,
    WifiIcon,
  },
  data() {
    return {
      serverURL: process.env.VUE_APP_CORECLIMATE_API,
      serverResponse: '',
      center: { lat: 50, lng: -40 },
      gateways: [],
      googleMapsAPIKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      clickedGateway: null,
      interval: null,
    };
  },
  methods: {
    showTitle(gateway) {
      console.log('gateway.core_gateway_id: ' + gateway.core_gateway_id);
      this.clickedGateway = gateway;
      this.show = true;
    },
    loadPage() {
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;

      console.log('polling gateways');
      axios
        .get(
          '/gateways?core_account_name=' +
            currentAccountSession +
            '&core_project_name=' +
            currentProjectSession
        )
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          console.log('Number of gateways: ' + res.data.count);
          this.gateways = res.data.data;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        })
        .finally(() => {
          console.log('this.gateways.length: ' + this.gateways.length);
          for (let i = 0; i < this.gateways.length; i++) {
            let time_diff = dateMath.diff(
              new Date(this.gateways[i].last_seen),
              new Date(),
              'minutes',
              false
            );
            // console.log('time diff: ' + time_diff);
            if (time_diff > 60 && this.gateways[i].live == true) {
              this.gateways[i].image = require('@/assets/red-marker.png');
              this.gateways[i].alerting = true;
            } else if (time_diff < 60 && this.gateways[i].live == true) {
              this.gateways[i].image = require('@/assets/green-marker.png');
              this.gateways[i].alerting = false;
            } else {
              this.gateways[
                i
              ].image = require('@/assets/yellow-marker-new.png');
              this.gateways[i].alerting = false;
            }
            //Let's round down the timing
            // If it's less than 60 mins, then we use the minute value
            // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
            // if it's more than 1440 mins then we use the lowest day
            if (time_diff < 60) {
              this.gateways[i].time_diff = time_diff + ' mins ago';
            } else if (time_diff >= 60 && time_diff < 1440) {
              this.gateways[i].time_diff =
                Math.floor(time_diff / 60) + ' hours ago';
            } else {
              this.gateways[i].time_diff =
                Math.floor(time_diff / 1440) + ' days ago';
            }
            // console.log('Time last seen: ' + this.gateways[i].time_diff);
            //this.gateways[i].time_diff = time_diff;
            //console.log('setting gateway.image: ' + this.gateways[i].image);
            this.gateways[i].route = '/gateways/' + this.gateways[i]._id;
          }
        });
    },
    // loadPage() {
    //   console.log('MapReport.created() called');
    //   let _this = this;
    //   this.$store.watch(
    //     () => this.$store.state.clime_account.name,
    //     function() {
    //       console.log('value changes detected - reloading map');
    //       _this.loadMap(_this.$store.state.clime_account.name);
    //     }
    //   );
    //   const currentAccountSession = this.$store.state.clime_account.name;
    //   console.log(
    //     'this.$store.state.clime_account.name: ' + this.$store.state.clime_account.name
    //   );
    //   this.loadMap(currentAccountSession);
    // },
  },
  mounted() {
    this.serverURL = process.env.VUE_APP_CORECLIMATE_API;
    console.log('serverURL : ' + this.serverURL);
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    this.loadPage();
    this.interval = setInterval(() => {
      this.loadPage();
    }, 10000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>
