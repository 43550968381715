<template>
  <div class="ml-5 mt-10 text-lg font-medium text-gray-500">
    Project Dashboard
  </div>

  <div class="bg-gray-100 mt-2 mx-2">
    <div
      class="filter flex shadow rounded bg-white py-2 mb-5 border border-transparent"
      v-if="panel_count != 0"
    >
      <div class="flex justify-start mr-5 mb-2 max-h-1">
        <Menu
          as="div"
          class="relative inline-block self-start ml-2 text-left w-36"
        >
          <div>
            <MenuButton
              class="inline-flex justify-center w-full rounded-md border border-gray-200 shadow-sm ml-2 px-1 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              Timeframe ({{ timeframe }})
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="z-40 origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem
                  v-for="time in timeframe_dropdown"
                  :key="time"
                  v-on:click="(this.timeframe = time), loadPage()"
                >
                  <div class="bg-white text-gray-900 block px-4 py-2 text-sm">
                    {{ time }}
                  </div>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div class="flex w-full"></div>

      <div class="mt-0 mr-5 min-w-max">
        <button
          type="button"
          v-on:click="addNewPanelRequest(true)"
          class="inline-flex items-center px-3 py-2 ml-5 border border-gray text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
        >
          <ViewGridAddIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Panel
        </button>
      </div>
    </div>

    <div class="text-center mt-20" v-if="panel_count == 0">
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        No Panels added to Dashboard
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Get started by adding a new Panel
      </p>
      <div class="mt-6">
        <button
          type="button"
          v-on:click="addNewPanelRequest(true)"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          <ViewGridAddIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Panel
        </button>
      </div>
    </div>
    <div class="" v-else>
      <div
        class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols2 lg:grid-cols-3"
      >
        <div v-for="panel in dataset" :key="panel.core_device_id">
          <div
            class="col-span-1 flex flex-col mt-2 mb-2 border bg-white rounded-xl mx-0 divide-y divide-gray-200"
          >
            <div class="ml-5 my-2">
              <div class="grid justify-items-stretch grid-cols-2">
                <div class="col-span-1 min-w-max">
                  <router-link :to="'/devices/' + panel._id_of_device_object">
                    {{ panel.core_device_id }}
                  </router-link>
                  <div class="text-xs font-sm text-gray-500">
                    {{ panel.capability }}
                  </div>
                </div>
                <div class="col-span-1 justify-self-end self-center">
                  <Menu as="div" class="ml-4 relative flex-shrink-0">
                    <div>
                      <MenuButton
                        class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none"
                      >
                        <DotsVerticalIcon class="w-5 h-5" aria-hidden="true" />
                      </MenuButton>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <MenuItem>
                          <div
                            class="bg-white block px-4 py-2 text-sm text-gray-700"
                            v-on:click="removePanel(panel.panel_id)"
                          >
                            Delete Panel
                          </div>
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-3 divide-x divide-gray-200">
              <div
                class="grid grid-rows-3 flex col-span-1 justify-items-stretch"
              >
                <div class="flex justify-center w-full mt-0">
                  <div class="text-4xl text-gray-500">
                    {{ panel.value }}
                  </div>
                  <div class="text-medium font-medium text-gray-500">
                    {{ panel.unit }}
                  </div>
                </div>
                <div
                  v-if="panel.cost != null && panel.cost != 0"
                  class="flex justify-center w-full mt-0"
                >
                  <div class="text-4xl text-gray-400">
                    {{ abbreviateNumber(panel.cost) }}
                  </div>
                  <div class="text-small font-small text-gray-400">€</div>
                </div>
                <div
                  v-if="panel.co2_cost != null && panel.co2_cost != 0"
                  class="flex justify-center w-full mt-0"
                >
                  <div class="text-4xl text-gray-400">
                    {{ abbreviateNumber(panel.co2_cost) }}
                  </div>
                  <div class="text-small font-small text-gray-400">
                    Kg CO<sub>2</sub>
                  </div>
                </div>
              </div>

              <div class="grid col-span-2 justify-items-stretch">
                <apexchart
                  width="100%"
                  height="150"
                  type="area"
                  :options="panel.options"
                  :series="panel.series"
                ></apexchart>
                <div class="text-xs font-sm text-gray-500 justify-self-end p-2">
                  {{ panel.last_seen }}
                </div>
                <div
                  class="flex justify-center py-5"
                  v-if="
                    panel.capability == 'electricity' ||
                    panel.capability == 'gas'
                  "
                >
                  <odometer-display
                    :number="panel.meter_digits"
                    :size="'small'"
                    :theme="'light'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-40"></div>
    <add-panel
      v-if="add_new_panel_request"
      :title_text="'Add new Panel'"
      :message_text="'Choose a metric to display as a panel'"
      :confirm_button_text="'Choose'"
      :core_account_name="$store.state.clime_account.name"
      :core_project_name="$store.state.clime_project.name"
      :devices="devices"
      v-on:user_action="addPanel($event)"
    ></add-panel>
  </div>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { ViewGridAddIcon } from '@heroicons/vue/outline';
import OdometerDisplay from '../components/OdometerDisplay.vue';
import {
  ChevronDownIcon,
  SearchIcon,
  DotsVerticalIcon,
} from '@heroicons/vue/solid';
import AddPanel from '../components/AddPanel.vue';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
export default {
  setup() {
    return {};
  },

  components: {
    ViewGridAddIcon,
    AddPanel,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    ChevronDownIcon,
    SearchIcon,
    DotsVerticalIcon,
    OdometerDisplay,
  },
  data() {
    return {
      panels: [],
      panels_already_displayed: [],
      devices: [],
      dataset: [],
      panel_count: 0,
      add_new_panel_request: false,
      timeframe: '1d',
      timeframe_dropdown: ['1h', '6h', '1d', '3d', '7d'],
    };
  },

  methods: {
    addNewPanelRequest(request) {
      console.log('addNewPanelRequest called with: ' + request);

      //Let's force this to false first regardless of the request
      this.add_new_panel_request = false;
      this.add_new_panel_request = request;
    },
    addPanel(result) {
      console.log('result: ');
      console.log(result);
      this.add_new_panel_request = false;

      if (result.is_confirming) {
        const panel = {};
        panel.account_name = this.$store.state.clime_account.name;
        panel.project_name = this.$store.state.clime_project.name;
        panel.core_device_id = result.new_panel.core_device_id;
        panel.capability = result.new_panel.capability;
        panel.user_email = this.$store.state.user.email;
        panel.panel_type = 'expanded';

        axios
          .post('/panels', panel)
          .then((res) => {
            this.loadPage();
          })
          .catch((err) => {
            console.log(err);
            this.serverResponse = JSON.stringify(err.response, null, 2);
          });
      }
    },
    removePanel(panel_id) {
      axios
        .delete('/panels/' + panel_id)
        .then((res) => {
          this.loadPage();
        })
        .catch((err) => {
          console.log(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    async loadPanel(
      _id_of_device_object,
      panel_id,
      capability,
      core_device_id,
      value,
      unit,
      price,
      co2,
      imp_per_unit,
      last_seen,
      meter_digits
    ) {
      let updated_capability = capability;
      if (capability == 'electricity') {
        updated_capability = 'electricity_series';
      } else if (capability == 'gas') {
        updated_capability = 'gas_series';
      } else if (capability == 'counter') {
        updated_capability = 'aisle_count&report_type=incremental';
      }

      axios
        .get(
          '/reports/device?measure_name=' +
            updated_capability +
            '&core_device_id=' +
            core_device_id +
            '&timeframe=' +
            this.timeframe
        )
        .then((res) => {
          let series = null;

          if (capability == 'electricity' || capability == 'gas') {
            let fine_grain_graph = false;
            if (this.timeframe == '1h') {
              fine_grain_graph = true;
            }

            let new_series_name = 'electricity-series-expanded';
            if (capability == 'gas') {
              new_series_name = 'gas-series-expanded';
            }

            let new_series = {
              name: new_series_name,
              data: [],
            };
            let new_series_data = [];

            for (let data of res.data.series[0].data) {
              let running_total_in_timeframe = 1;
              let last_timestamp = null;
              let i = 0;

              for (let expanded_row of data.y) {
                i++;

                let row_value = expanded_row.value;
                if (fine_grain_graph) {
                  if (row_value != 'NaN') {
                    new_series_data.push({
                      x: expanded_row.timestamp,
                      y: (row_value / imp_per_unit).toFixed(2),
                    });
                  }
                } else {
                  if (row_value != 'NaN') {
                    running_total_in_timeframe =
                      running_total_in_timeframe + expanded_row.value;
                    last_timestamp = expanded_row.timestamp;
                  }
                }
              }

              if (!fine_grain_graph) {
                // //let average = parseInt(running_total_in_timeframe / i, 10);
                // let average = Math.round(running_total_in_timeframe / i);
                // console.log(
                //   'running_total_in_timeframe: ' + running_total_in_timeframe
                // );
                // console.log('average: ' + average);

                // if (average) {
                //   new_series_data.push({
                //     x: last_timestamp,
                //     y: (average * meter_ratio).toFixed(),
                //   });
                // }
                let final_reading = null;

                if (capability == 'electricity') {
                  final_reading = Math.round(running_total_in_timeframe / i);
                  //console.log('final_reading - electricity: ' + final_reading);
                } else if (capability == 'gas') {
                  final_reading = running_total_in_timeframe;
                  // console.log('final_reading - gas: ' + final_reading);
                }

                if (final_reading) {
                  new_series_data.push({
                    x: last_timestamp,
                    y: (final_reading / imp_per_unit).toFixed(2),
                  });
                }
              }
            }

            new_series.data = new_series_data;
            series = [new_series];
          } else {
            series = res.data.series;
          }
          // console.log('series: ');
          // console.log(series);

          let set = {
            core_device_id: core_device_id,
            _id_of_device_object: _id_of_device_object,
            capability: capability,
            value: value,
            unit: unit,
            price: price,
            co2: co2,
            co2_cost: null,
            cost: null,
            panel_id: panel_id,
            last_seen: last_seen,
            meter_digits: meter_digits,
            options: {
              theme: {
                palette: 'palette2',
              },

              chart: {
                stacked: false,
                type: 'area',
                height: 350,
                zoom: {
                  type: 'x',
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  show: false,
                },
              },
              stroke: {
                show: true,
                curve:
                  capability == 'presence' ||
                  capability == 'door_open' ||
                  capability == 'flood_alarm' ||
                  capability == 'counter'
                    ? 'stepline'
                    : 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 1,
                dashArray: 0,
              },

              dataLabels: {
                enabled: false,
              },

              tooltip: {
                enabled: true,
                x: {
                  show: true,
                  format: 'dd MMM HH:mm',
                },
              },

              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
                },
              },
            },
            series: series,
          };

          //Once we have this graph loaded, let's get the value for the meter readings
          let measure_name = set.capability;

          //Because we previously renamed electricity to electricity_value in the db - we need to modify it here
          if (set.capability == 'electricity') {
            measure_name = 'electricity_value';
          }

          axios
            .get(
              '/reports/device-meter?measure_name=' +
                measure_name +
                '&core_device_id=' +
                core_device_id +
                '&timeframe=' +
                this.timeframe
            )
            .then((res) => {
              // console.log('=========RESULT ============');
              // console.log(res);

              if (set.capability == 'electricity' || set.capability == 'gas') {
                if (res.data.success) {
                  set.value = res.data.data.toFixed(0);
                  set.cost = res.data.data * set.price;
                  set.co2_cost = res.data.data * set.co2;
                }
              }

              //Let's remove duplicate panels here. The reason this happens is if the Account is changed (which will rarely happen for
              //a customer) _loadPage() is called twice which ends up with duplicate panels
              if (!this.panels_already_displayed.includes(set.panel_id)) {
                this.panels_already_displayed.push(set.panel_id);
                this.dataset.push(set);
              }
            })
            .catch({});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      //console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    abbreviateNumber(number) {
      //console.log('abbreviateNumber: ' + number);
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) return number.toFixed(0);

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;

      // format number and add suffix
      //console.log('returning -- ' + scaled.toFixed(0) + suffix);
      return scaled.toFixed(0) + suffix;
    },
    loadPage() {
      this.dataset = [];
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;

      //Let's remove the panels just incase there's any left over from a different session
      this.panels = [];
      this.panels_already_displayed = [];

      // console.log(
      //   'Making Request: ' +
      //     '/panels?core_account_name=' +
      //     currentAccountSession +
      //     '&core_project_name=' +
      //     currentProjectSession
      // );

      axios
        .get(
          '/devices?core_account_name=' +
            currentAccountSession +
            '&core_project_name=' +
            currentProjectSession
        )
        .then((res) => {
          // console.log('devices - res.data.count: ' + res.data.count);
          this.devices = res.data.data;

          axios
            .get(
              '/panels?core_account_name=' +
                currentAccountSession +
                '&core_project_name=' +
                currentProjectSession
            )
            .then((res) => {
              //  console.log('res.data.count: ' + res.data.count);
              this.panel_count = res.data.count;
              this.panels = res.data.data;

              for (let panel of this.panels) {
                const current_device = this.devices.filter((device) => {
                  return device.core_device_id.includes(panel.core_device_id);
                });

                if (current_device[0]) {
                  // console.log('Found Device=======');
                  // console.log(current_device[0]);

                  let value = null;
                  let unit = null;
                  let price = null;
                  let co2 = null;
                  // let meter_ratio = null;
                  let imp_per_unit = null;
                  let meter_digits = '00000';

                  let _id_of_device_object = current_device[0]._id;
                  if (panel.capability == 'temperature') {
                    value = Number.parseFloat(
                      current_device[0].temperature
                    ).toFixed(1);
                    unit = 'c';
                  } else if (panel.capability == 'temperature_probe') {
                    value = Number.parseFloat(
                      current_device[0].temperature_probe
                    ).toFixed(1);
                    unit = 'c';
                  } else if (panel.capability == 'current') {
                    value = Number.parseFloat(
                      current_device[0].current / 1000
                    ).toFixed(1);
                    unit = 'Amps';
                  } else if (panel.capability == 'humidity') {
                    value = Math.round(current_device[0].humidity);
                    unit = '%';
                  } else if (panel.capability == 'battery') {
                    value = Number.parseFloat(
                      current_device[0].battery
                    ).toFixed(1);
                    unit = 'v';
                  } else if (panel.capability == 'counter') {
                    value = this.abbreviateNumber(current_device[0].counter);
                    unit = '';
                  } else if (panel.capability == 'door_open') {
                    if (current_device[0].door_open == true) {
                      value = 'Open';
                    } else {
                      value = 'Closed';
                    }
                    unit = '';
                  } else if (panel.capability == 'flood_alarm') {
                    if (current_device[0].flood_alarm == true) {
                      value = 'SOS';
                    } else {
                      value = 'OK';
                    }
                    unit = '';
                  } else if (panel.capability == 'presence') {
                    if (current_device[0].presence == true) {
                      value = 'Taken';
                    } else {
                      value = 'Free';
                    }
                    unit = '';
                  } else if (panel.capability == 'eco2') {
                    value = Math.round(current_device[0].eco2);
                    unit = 'ppm';
                  } else if (panel.capability == 'co2') {
                    value = Math.round(current_device[0].co2);
                    unit = 'ppm';
                  } else if (panel.capability == 'hcho') {
                    value = Math.round(current_device[0].hcho).toFixed(1);
                    unit = 'mglm3';
                  } else if (panel.capability == 'pm10') {
                    value = Math.round(current_device[0].pm10);
                    unit = 'μglm3';
                  } else if (panel.capability == 'pm2_5') {
                    value = Math.round(current_device[0].pm2_5);
                    unit = 'μglm3';
                  } else if (panel.capability == 'pressure') {
                    value = Math.round(current_device[0].pressure);
                    unit = 'Pa';
                  } else if (panel.capability == 'light_level') {
                    value = Math.round(current_device[0].eco2);
                    unit = '';
                  } else if (panel.capability == 'tvoc') {
                    value = Math.round(current_device[0].tvoc);
                    unit = 'ppb';
                  } else if (panel.capability == 'electricity') {
                    // value = Number.parseFloat(
                    //   current_device[0].electricity / 1000
                    // ).toFixed(0);
                    unit = 'kWh';
                    price = current_device[0].price_per_unit;
                    co2 = current_device[0].co2_per_unit;
                    // meter_ratio =
                    //   1000 / current_device[0].imp_per_unit;
                    imp_per_unit = current_device[0].imp_per_unit;
                    meter_digits = Math.round(
                      current_device[0].base_meter_reading_array[0]
                        .base_reading +
                        current_device[0].electricity /
                          current_device[0].imp_per_unit
                    ).toString();
                  } else if (panel.capability == 'gas') {
                    unit = 'm3';
                    price = current_device[0].price_per_unit;
                    co2 = current_device[0].co2_per_unit;
                    //meter_ratio = 1 / current_device[0].imp_per_unit;
                    imp_per_unit = current_device[0].imp_per_unit;
                    if (current_device[0].gas) {
                      meter_digits = Math.round(
                        current_device[0].base_meter_reading_array[0]
                          .base_reading +
                          current_device[0].gas / current_device[0].imp_per_unit
                      ).toString();
                    } else {
                      meter_digits = 'waiting';
                    }
                  }

                  const last_seen = this.getTimeDiff(
                    dateMath.diff(
                      new Date(current_device[0].last_seen),
                      new Date(),
                      'minutes',
                      false
                    )
                  );

                  this.loadPanel(
                    _id_of_device_object,
                    panel._id,
                    panel.capability,
                    panel.core_device_id,
                    value,
                    unit,
                    price,
                    co2,
                    imp_per_unit,
                    last_seen,
                    meter_digits
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {},
  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },
  created() {
    this.loadPage();
  },
  watch: {
    add_new_panel_request() {
      // console.log('add_new_panel_request: ' + this.add_new_panel_request);
    },
  },
};
</script>
