<template>
  <li
    class="col-span-1 flex flex-col text-center bg-white rounded-lg filter drop-shadow-lg divide-y divide-gray-100"
  >
    <div>
      <div class="-mt-px flex divide-x divide-gray-100 p-5">
        <div
          v-if="product_name == 'core-01-868-temp-sensor'"
          class="w-0 flex-1 flex justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="48px"
            viewBox="0 0 24 24"
            width="48px"
            fill="#6366F1"
          >
            <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
            <g>
              <g>
                <path
                  d="M15,13V5c0-1.66-1.34-3-3-3S9,3.34,9,5v8c-1.21,0.91-2,2.37-2,4c0,2.76,2.24,5,5,5s5-2.24,5-5C17,15.37,16.21,13.91,15,13 z M11,11V5c0-0.55,0.45-1,1-1s1,0.45,1,1v1h-1v1h1v1v1h-1v1h1v1H11z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div
          v-else-if="product_name == 'core-01-868-door-sensor'"
          class="w-0 flex-1 flex justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 20 20"
            height="48px"
            viewBox="0 0 20 20"
            width="48px"
            fill="#6366F1"
          >
            <g>
              <rect fill="none" height="20" width="20" />
              <path
                d="M15,4v12H5V4H15 M15,3H5C4.45,3,4,3.45,4,4v13h12V4C16,3.45,15.55,3,15,3L15,3z M13,9c-0.55,0-1,0.45-1,1s0.45,1,1,1 s1-0.45,1-1S13.55,9,13,9z"
              />
            </g>
          </svg>
        </div>
        <div
          v-else-if="product_name == 'core-01-868-air-sensor'"
          class="w-0 flex-1 flex justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="48px"
            viewBox="0 0 24 24"
            width="48px"
            fill="#6366F1"
          >
            <g>
              <rect fill="none" height="20" width="20" />
              <path
                d="M14.5,17c0,1.65-1.35,3-3,3s-3-1.35-3-3h2c0,0.55,0.45,1,1,1s1-0.45,1-1s-0.45-1-1-1H2v-2h9.5 C13.15,14,14.5,15.35,14.5,17z M19,6.5C19,4.57,17.43,3,15.5,3S12,4.57,12,6.5h2C14,5.67,14.67,5,15.5,5S17,5.67,17,6.5 S16.33,8,15.5,8H2v2h13.5C17.43,10,19,8.43,19,6.5z M18.5,11H2v2h16.5c0.83,0,1.5,0.67,1.5,1.5S19.33,16,18.5,16v2 c1.93,0,3.5-1.57,3.5-3.5S20.43,11,18.5,11z"
              />
            </g>
          </svg>
        </div>
        <div
          v-else-if="product_name == 'core-01-868-traffic-sensor'"
          class="w-0 flex-1 flex justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 0 24 24"
            width="48px"
            fill="#6366F1"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M13.49 5.48c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-3.6 13.9l1-4.4 2.1 2v6h2v-7.5l-2.1-2 .6-3c1.3 1.5 3.3 2.5 5.5 2.5v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1l-5.2 2.2v4.7h2v-3.4l1.8-.7-1.6 8.1-4.9-1-.4 2 7 1.4z"
            />
          </svg>
        </div>
        <div class="w-0 flex-1 justify-start pt-0">
          <p class="text-sm font-medium text-gray-900 text-left">
            Temp Int: {{ temperature }} &deg;C
          </p>
          <p
            v-if="product_name == 'core-01-868-temp-sensor'"
            class="text-sm font-medium text-gray-900 text-left"
          >
            Temp Probe: {{ temperature_probe }} &deg;C
          </p>
          <p class="text-sm font-medium text-gray-900 text-left">
            Humidity: {{ humidity }} %
          </p>
          <p class="text-sm font-medium text-gray-900 text-left">
            Battery: {{ battery }} V
          </p>
          <p
            v-if="product_name == 'core-01-868-door-sensor'"
            class="text-sm font-medium text-gray-900 text-left"
          >
            Door Open: {{ door_open }}
          </p>
          <p
            v-if="product_name == 'core-01-868-traffic-sensor'"
            class="text-sm font-medium text-gray-900 text-left"
          >
            Counter: {{ counter }}
          </p>
          <p
            v-if="product_name == 'core-01-868-air-sensor'"
            class="text-sm font-medium text-gray-900 text-left"
          >
            eCO2: {{ eco2 }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex-1 flex flex-col p-0">
      <h3 class="mt-2 text-gray-900 text-lg font-medium">{{ name }}</h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Last Seen</dt>
        <dd class="mt-1 mb-2">
          <span
            class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full"
            >Last Seen: {{ getLastSeen(last_seen) }}</span
          >
        </dd>
      </dl>
    </div>
    <div class="flex-1 flex flex-col p-2 bg-gray-50 px-4 py-4 sm:px-6">
      <div class="text-sm">
        <router-link :to="'/devices/' + _id">
          <p class="font-medium text-indigo-600 hover:text-indigo-500">
            View Device
          </p>
        </router-link>
      </div>
    </div>
  </li>
</template>

<script>
import * as dateMath from 'date-arithmetic';

export default {
  props: {
    name: { required: true, type: String },
    humidity: { required: false, type: Number },
    counter: { required: false, type: Number },
    battery: { required: false, type: Number },
    door_open: { required: false, type: Boolean },
    temperature: { required: false, type: Number },
    temperature_probe: { required: false, type: Number },
    eco2: { required: false, type: Number },
    last_seen: { required: true, type: Date },
    product_name: { required: false, type: String },
    _id: { required: true, type: String },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    getLastSeen(last_seen) {
      let last_seen_in_words = '';

      let time_diff = dateMath.diff(
        new Date(last_seen),
        new Date(),
        'minutes',
        false
      );

      if (time_diff < 60) {
        last_seen_in_words = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        let hours = Math.floor(time_diff / 60);
        if (hours == 1) {
          last_seen_in_words = hours + ' hour ago';
        } else {
          last_seen_in_words = hours + ' hours ago';
        }
      } else {
        let days = Math.floor(time_diff / 1440);
        if (days == 1) {
          last_seen_in_words = days + ' day ago';
        } else {
          last_seen_in_words = days + ' days ago';
        }
      }

      return last_seen_in_words;
    },
  },
};
</script>
