<template>
  <div class="py-6 px-6">
    <!-- Replace with your content -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <span class="text-lg leading-6 font-medium text-gray-900">
          User Email: {{ user.email }}
        </span>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">User Email</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <div v-if="user.email == current_user_email">
                      <input
                        type="text"
                        class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                        v-model="user.email"
                        disabled
                      />
                    </div>
                    <div v-else>
                      <input
                        type="text"
                        class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                        v-model="user.email"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="isDisabled(user.email)"
                  class="text-xs text-red-900 lg:mr-32"
                >
                  Cannot edit your own email as this will log you out of the
                  system. Please add another email address rather than editing
                  this one
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Account Role</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <!-- column for product dropdown  -->
                  <td class="px-0 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div v-if="!new_user">
                      <div
                        class="mt-1 w-full relative rounded-md shadow-sm pl-1"
                        v-for="(account, accountIdx) in user.core_accounts"
                        :key="account.account_name"
                      >
                        <div v-if="account.account_name == core_account_name">
                          <select
                            v-model="
                              user.core_accounts[accountIdx].account_role
                            "
                            class="mt-1 px-10 pl-5 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option
                              v-for="role in account_roles_enum"
                              :key="role.id"
                              :disabled="isDisabled(user.email)"
                            >
                              {{ role.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="mt-1 w-full relative rounded-md shadow-sm pl-1"
                      >
                        <select
                          v-model="user.core_accounts[0].account_role"
                          class="mt-1 px-10 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option
                            v-for="role in account_roles_enum"
                            :key="role.id"
                          >
                            {{ role.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <div
                    v-if="isDisabled(user.email)"
                    class="text-xs text-red-900 lg:mr-32"
                  >
                    Cannot edit your own role as this will remove your editing
                    rights. Please contact another admin to change this for you
                    if needed.
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Account Homepage
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <!-- column for product dropdown  -->
                  <td class="px-0 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div v-if="!new_user">
                      <div
                        class="mt-1 w-full relative rounded-md shadow-sm pl-1"
                        v-for="(account, accountIdx) in user.core_accounts"
                        :key="account.account_name"
                      >
                        <div v-if="account.account_name == core_account_name">
                          <select
                            v-model="
                              user.core_accounts[accountIdx].account_homepage
                            "
                            class="mt-1 px-10 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option
                              v-for="homepage in available_homepages"
                              :key="homepage.id"
                            >
                              {{ homepage.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="mt-1 w-full relative rounded-md shadow-sm pl-1"
                      >
                        <select
                          v-model="user.core_accounts[0].account_homepage"
                          class="mt-1 px-10 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option
                            v-for="homepage in available_homepages"
                            :key="homepage.id"
                          >
                            {{ homepage.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div v-if="userAlertBottom.status == 'success'">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  {{ userAlertBottom.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <div v-if="!new_user">
              <feedback-button
                v-if="deleteButtonState == 'none'"
                v-on:click="confirmRemoveUser = true"
                :text="'Delete User'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>

              <confirm-action
                v-if="confirmRemoveUser"
                :title_text="'Delete User'"
                :message_text="'Are you sure you want to Delete this User? This action cannot be undone!'"
                :confirm_button_text="'Delete User'"
                @userAction="removeUser($event)"
              ></confirm-action>
            </div>

            <div v-if="new_user">
              <feedback-button
                v-if="submitButtonState == 'none'"
                v-on:click="addUser"
                :text="'Add User'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
            </div>
            <div v-else>
              <feedback-button
                v-if="submitButtonState == 'none'"
                v-on:click="updateUser"
                :text="'Submit'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
            </div>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';
import LoadingWidget from '../../components/LoadingWidget.vue';
import FeedbackButton from '../../components/FeedbackButton.vue';
import ConfirmAction from '../../components/ConfirmAction.vue';
import VueTagsInput from '@sipec/vue3-tags-input';

const account_roles_enum = [
  { id: 1, name: 'admin' },
  { id: 2, name: 'user' },
  { id: 3, name: 'monitor' },
  { id: 4, name: 'install' },
];

const available_homepages = [
  { id: 1, name: 'mapReport' },
  { id: 2, name: 'temperatureReport' },
  { id: 3, name: 'trafficReport' },
  { id: 4, name: 'doorReport' },
  { id: 5, name: 'batteryReport' },
  { id: 6, name: 'humidityReport' },
  { id: 7, name: 'airQualityReport' },
  { id: 8, name: 'platformSummaryReport' },
];

export default {
  components: {
    ServerResponse,
    AlertBanner,
    LoadingWidget,
    FeedbackButton,
    ConfirmAction,
    VueTagsInput,
  },

  setup() {
    return {
      account_roles_enum,
      available_homepages,
    };
  },

  data() {
    return {
      user: '',
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      confirmRemoveUser: false,
      confirmUserAction: false,
      showLoading: false,
      userAlertBottom: {
        status: '',
        message: '',
      },
      core_account_name: null,
      add_user: false,
      current_user_email: null,
    };
  },

  methods: {
    isDisabled(email) {
      if (email == this.current_user_email) {
        return true;
      } else return false;
    },
    addUser() {
      this.submitButtonState = 'loading';

      axios
        .post('/users?core_account_name=' + this.core_account_name, this.user)
        .then((res) => {
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    updateUser() {
      this.submitButtonState = 'loading';

      axios
        .put('/users/' + this.$route.params.id, this.user)
        .then((res) => {
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    removeUser(userConfirmed) {
      console.log('removeUser() called with userConfirmed: ' + userConfirmed);
      this.confirmRemoveUser = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';

        //so we're not actually going to delete the user, we are just going to remove
        //this account from the user in question
        for (let i = 0; i < this.user.core_accounts.length; i++) {
          if (
            this.user.core_accounts[i].account_name == this.core_account_name
          ) {
            this.user.core_accounts.splice(i, 1);
          }
        }
        console.log(
          'removed account from user - resulting user object as follows'
        );
        console.log(this.user);
        this.updateUser();
        this.deleteButtonState = 'done';
      }
    },
    refresh() {
      this.showLoading = true;
      axios
        .get('/users/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.user = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .then((res) => {
          //Just to give some feeling of action to the user
          //Without this, it feels too quick and there's no sense of change
          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },

  created() {
    this.core_account_name = this.$store.state.clime_account.name;
    this.current_user_email = this.$auth.user.value.email;

    if (this.$route.path === '/project-settings/users/new') {
      console.log('Creating new user');
      this.new_user = true;

      this.user = {
        name: '',
        email: '',
        core_accounts: [
          {
            account_name: this.core_account_name,
            account_role: '',
            account_homepage: 'mapReport',
          },
        ],
      };
    } else {
      axios
        .get('/users/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.user = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })

        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    }
  },
};
</script>
