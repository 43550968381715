<template>
  <div class="py-6 px-6">
    <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">Meter Detail</h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"> -->
    <!-- Replace with your content -->

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-xl leading-6 font-medium text-gray-900 mb-10">
          Building Details: {{ building.name }}
        </h3>
        <div class="grid sm:grid-cols-2 mb-10 grid-cols-1">
          <div class="col-span-1">
            <img
              v-if="show_induchem_ie"
              class="h-48 w-full object-cover sm:pr-5"
              src="../assets/induchem.png"
              alt="Coreclimate"
            />
            <img
              v-if="show_induchem_uk"
              class="h-48 w-full object-cover sm:pr-5"
              src="../assets/induchem-uk.png"
              alt="Coreclimate"
            />
            <img
              v-if="show_tredwell"
              class="h-48 w-full object-cover sm:pr-5"
              src="../assets/tredwell.png"
              alt="Coreclimate"
            />
          </div>

          <div class="col-span-1">
            <!-- start map --->
            <div class="mt-0 sm:mt-5 space-y-2 sm:space-y-5">
              <div
                class="mt-0 sm:col-span-3 align-middle justify-self-center border"
              >
                <GoogleMap
                  :api-key="googleMapsAPIKey"
                  style="width: 100%; height: 150px"
                  :center="center"
                  :zoom="8"
                  v-if="showMap && storeLocation"
                >
                  <Marker
                    :options="{
                      position: center,
                      icon: require('@/assets/green-marker.png'),
                    }"
                  />
                </GoogleMap>
              </div>
              <div
                class="sm:grid sm:grid-cols-5 sm:gap-2 sm:items-start sm:pt-0 bg-gray-100"
              >
                <label
                  class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 pl-5"
                >
                  Location
                </label>
                <div class="mt-1 flex col-span-3 rounded-md shadow-sm">
                  <span
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                    ]"
                    @click="useCurrentLocation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="geoMessage"
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                    ]"
                  />

                  <div class="ml-4 mt-2">
                    <p class="text-sm text-gray-500">
                      <RefreshIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                        @click="refreshLocation"
                      />
                    </p>
                  </div>
                </div>

                <div
                  class="mt-2 sm:col-span-1 align-middle justify-self-center"
                >
                  <Switch
                    v-model="storeLocation"
                    :class="[
                      storeLocation ? 'bg-sky-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                    ]"
                  >
                    <span class="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        storeLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                      ]"
                    />
                  </Switch>
                </div>
              </div>
            </div>
            <!-- end map -->
          </div>
        </div>

        <div>
          <h1 class="text-lg font-semibold leading-6 text-gray-900">
            2022 ESG Review
          </h1>

          <dl
            class="mt-5 mb-10 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3"
          >
            <div
              class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div class="absolute bg-blue-50 rounded-md p-3">
                  <component
                    :is="LightningBoltIcon"
                    class="h-6 w-6 text-blue-700"
                    aria-hidden="true"
                  />
                </div>
                <p class="ml-16 truncate text-sm font-medium text-gray-500">
                  Total kWh Used in 2022
                </p>
              </dt>
              <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{ abbreviateNumber(running_total_kwh_2022) }}
                </p>
                <p
                  class="text-grey-600 ml-2 flex items-baseline text-sm font-semibold"
                >
                  <ArrowUpIcon
                    v-if="running_total_kwh_2022 > running_total_kwh_2021"
                    class="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  <ArrowDownIcon
                    v-else
                    class="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />

                  {{
                    abbreviateNumber(
                      running_total_kwh_2022 - running_total_kwh_2021
                    )
                  }}
                </p>
                <div
                  class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6"
                >
                  <div class="text-sm">
                    <!-- <a
                      href="/reports/energyReport"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all<span class="sr-only"> Energy stats</span></a
                    > -->
                    <router-link
                      to="/reports/energyReport"
                      class="focus:outline-none"
                    >
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true" />
                      Energy stats
                    </router-link>
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div class="absolute bg-green-50 rounded-md p-3">
                  <component
                    :is="CurrencyEuroIcon"
                    class="h-6 w-6 text-green-700"
                    aria-hidden="true"
                  />
                </div>
                <p class="ml-16 truncate text-sm font-medium text-gray-500">
                  Total cost 2022
                </p>
              </dt>
              <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{ abbreviateNumber(running_total_cost_2022) }}
                </p>
                <p
                  class="text-grey-600 ml-2 flex items-baseline text-sm font-semibold"
                >
                  <ArrowUpIcon
                    v-if="running_total_cost_2022 > running_total_cost_2021"
                    class="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  <ArrowDownIcon
                    v-else
                    class="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />

                  {{
                    abbreviateNumber(
                      running_total_cost_2022 - running_total_cost_2021
                    )
                  }}
                </p>
                <div
                  class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6"
                >
                  <div class="text-sm">
                    <!-- <a
                      href="/reports/energyReport"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all<span class="sr-only"> Energy stats</span></a
                    > -->
                    <router-link
                      to="/reports/energyReport"
                      class="focus:outline-none"
                    >
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true" />
                      Energy stats
                    </router-link>
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div class="absolute bg-red-50 rounded-md p-3">
                  <component
                    :is="CloudUploadIcon"
                    class="h-6 w-6 text-red-700"
                    aria-hidden="true"
                  />
                </div>
                <p
                  class="ml-16 pb-2 truncate text-sm font-medium text-gray-500"
                >
                  Total CO<sub>2</sub> Emissions for 2022
                </p>
              </dt>
              <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{ abbreviateWeight(running_total_co2_2022) }}
                </p>
                <p
                  class="text-grey-600 ml-2 flex items-baseline text-sm font-semibold"
                >
                  <ArrowUpIcon
                    v-if="running_total_co2_2022 > running_total_co2_2021"
                    class="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  <ArrowDownIcon
                    v-else
                    class="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />

                  {{
                    abbreviateWeight(
                      running_total_co2_2022 - running_total_co2_2021
                    )
                  }}
                </p>
                <div
                  class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6"
                >
                  <div class="text-sm">
                    <!-- <a
                      href="/reports/energyReport"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all<span class="sr-only"> Energy stats</span></a
                    > -->
                    <router-link
                      to="/reports/energyReport"
                      class="focus:outline-none"
                    >
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true" />
                      Energy stats
                    </router-link>
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div class="absolute bg-blue-50 rounded-md p-3">
                  <component
                    :is="OfficeBuildingIcon"
                    class="h-6 w-6 text-blue-700"
                    aria-hidden="true"
                  />
                </div>
                <p
                  class="ml-16 pt-2 truncate text-sm font-medium text-gray-500"
                >
                  Energy/M<sup>2</sup> (KWh/M<sup>2</sup> )
                </p>
              </dt>
              <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{
                    abbreviateNumber(
                      running_total_kwh_2022 / building.square_meters
                    )
                  }}
                </p>
                <p
                  class="text-grey-600 ml-2 flex items-baseline text-sm font-semibold"
                >
                  <ArrowUpIcon
                    v-if="running_total_co2_2022 > running_total_co2_2021"
                    class="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  <ArrowDownIcon
                    v-else
                    class="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />

                  {{
                    abbreviateNumber(
                      running_total_kwh_2022 / building.square_meters -
                        running_total_kwh_2021 / building.square_meters
                    )
                  }}
                </p>
                <div
                  class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6"
                >
                  <div class="text-sm">
                    <!-- <a
                      href="/reports/energyReport"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all<span class="sr-only"> Energy stats</span></a
                    > -->
                    <router-link
                      to="/reports/energyReport"
                      class="focus:outline-none"
                    >
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true" />
                      Energy stats
                    </router-link>
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div class="absolute bg-green-50 rounded-md p-3">
                  <component
                    :is="OfficeBuildingIcon"
                    class="h-6 w-6 text-green-700"
                    aria-hidden="true"
                  />
                </div>
                <p
                  class="ml-16 pt-2 truncate text-sm font-medium text-gray-500"
                >
                  Cost/M<sup>2</sup> (€/M<sup>2</sup> )
                </p>
              </dt>
              <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{
                    abbreviateNumber(
                      running_total_cost_2022 / building.square_meters
                    )
                  }}
                </p>
                <p
                  class="text-grey-600 ml-2 flex items-baseline text-sm font-semibold"
                >
                  <ArrowUpIcon
                    v-if="running_total_cost_2022 > running_total_cost_2021"
                    class="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  <ArrowDownIcon
                    v-else
                    class="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />

                  {{
                    abbreviateNumber(
                      running_total_cost_2022 / building.square_meters -
                        running_total_cost_2021 / building.square_meters
                    )
                  }}
                </p>
                <div
                  class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6"
                >
                  <div class="text-sm">
                    <!-- <a
                      href="/reports/energyReport"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all<span class="sr-only"> Energy stats</span></a
                    > -->
                    <router-link
                      to="/reports/energyReport"
                      class="focus:outline-none"
                    >
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true" />
                      Energy stats
                    </router-link>
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div class="absolute bg-red-50 rounded-md p-3">
                  <component
                    :is="OfficeBuildingIcon"
                    class="h-6 w-6 text-red-700"
                    aria-hidden="true"
                  />
                </div>
                <p
                  class="ml-16 pt-2 pb-2 truncate text-sm font-medium text-gray-500"
                >
                  CO<sub>2</sub>/M<sup>2</sup> (KG/M<sup>2</sup> )
                </p>
              </dt>
              <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{
                    abbreviateWeight(
                      running_total_co2_2022 / building.square_meters
                    )
                  }}
                </p>
                <p
                  class="text-grey-600 ml-2 flex items-baseline text-sm font-semibold"
                >
                  <ArrowUpIcon
                    v-if="running_total_co2_2022 > running_total_co2_2021"
                    class="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  <ArrowDownIcon
                    v-else
                    class="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />

                  {{
                    abbreviateWeight(
                      running_total_co2_2022 / building.square_meters -
                        running_total_co2_2021 / building.square_meters
                    )
                  }}
                </p>
                <div
                  class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6"
                >
                  <div class="text-sm">
                    <!-- <a
                      href="/reports/energyReport"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all<span class="sr-only"> Energy stats</span></a
                    > -->
                    <router-link
                      to="/reports/energyReport"
                      class="focus:outline-none"
                    >
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true" />
                      Energy stats
                    </router-link>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div v-if="chart">
          <div class="filter shadow mt-2 mb-2 border bg-white rounded-md">
            <apexchart
              width="100%"
              height="300"
              :options="chart.options"
              :series="chart.series"
            ></apexchart>
          </div>
        </div>

        <div
          v-for="meter in building_meters"
          :key="meter"
          class="filter shadow mt-10 mb-2 border rounded-md"
        >
          <div class="px-4 py-4 sm:px-6 lg:px-8 bg-gray-50">
            <div class="sm:flex sm:items-center">
              <div class="sm:flex-auto">
                <div class="mt-0 text-sm font-semibold leading-6 text-gray-900">
                  Invoices for {{ meter.reference_number }} [{{ meter.type }}]
                  [{{ meter.description }}]
                </div>
              </div>
            </div>
            <div class="mt-8 flow-root bg-white p-5 border-0 rounded">
              <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-64 overflow-auto"
                >
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Start Date
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          End Date
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Days
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Invoice Number
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          kWh
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Cost
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Download
                        </th>
                      </tr>
                    </thead>

                    <tbody class="divide-y divide-gray-200">
                      <tr v-for="invoice in meter.invoices" :key="invoice">
                        <td
                          class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                        >
                          {{ format_date(invoice.start_date) }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ format_date(invoice.end_date) }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{
                            (new Date(invoice.end_date) -
                              new Date(invoice.start_date)) /
                            (24 * 60 * 60 * 1000)
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ invoice.invoice_number }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ invoice.kwh.toLocaleString() }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{
                            parseFloat(invoice.cost.toFixed(0)).toLocaleString()
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-0"
                        >
                          <DocumentDownloadIcon
                            class="flex-shrink-0 h-7 w-7 ml-5 text-gray-400"
                            v-on:click="download(invoice.pdf_id)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="filter shadow mt-10 mb-2 border bg-white rounded-md">
          <div class="px-4 sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-center">
              <div class="sm:flex-auto">
                <h1
                  class="mt-10 text-base font-semibold leading-6 text-gray-900"
                >
                  Invoices
                </h1>
                <p class="mt-2 text-sm text-gray-700">
                  A list of all the invoices available in the Utility account
                </p>
              </div>
            </div>
            <div class="mt-8 flow-root">
              <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-64 overflow-auto"
                >
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Start Date
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          End Date
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Days
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Invoice Number
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          kWh
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Cost
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Download
                        </th>
                      </tr>
                    </thead>

                    <tbody class="divide-y divide-gray-200">
                      <tr v-for="invoice in meter.invoices" :key="invoice">
                        <td
                          class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                        >
                          {{ format_date(invoice.start_date) }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ format_date(invoice.end_date) }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{
                            (new Date(invoice.end_date) -
                              new Date(invoice.start_date)) /
                            (24 * 60 * 60 * 1000)
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ invoice.invoice_number }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ invoice.kwh.toLocaleString() }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{
                            parseFloat(invoice.cost.toFixed(0)).toLocaleString()
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-0"
                        >
                          <DocumentDownloadIcon
                            class="flex-shrink-0 h-7 w-7 ml-5 text-gray-400"
                            v-on:click="download(invoice.pdf_id)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="ml-0 mt-0">
          <div class="flex items-center">
            <!-- <div class="flex-shrink-0 mt-0 max-w-2xl text-sm text-gray-500">
              Last Sync
              {{
                getTimeDiff(
                  dateMath.diff(
                    new Date(meter.last_seen),
                    new Date(),
                    'minutes',
                    false
                  )
                )
              }}
            </div> -->
            <!-- <div class="ml-4">
              <p class="text-sm text-gray-500">
                <RefreshIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  @click="refresh"
                />
              </p>
            </div> -->
          </div>
        </div>

        <p></p>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Building Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="building.name"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Description</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="building.description"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Address</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="building.address"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Size (square meters)
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="building.square_meters"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Building Meters</dt>

              <dd
                class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1 border-2 rounded bg-white p-5"
              >
                <div class="mb-10">
                  <div v-for="meter in building.meters" :key="meter">
                    <div class="grid grid-cols-4">
                      <div class="col-span-3">
                        <div v-if="meter_map.get(meter.meter_id)">
                          {{ meter_map.get(meter.meter_id).description }} ({{
                            meter_map.get(meter.meter_id).type
                          }})
                        </div>
                      </div>
                      <div>
                        <div class="ml-auto pl-3">
                          <div class="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              v-on:click="removeMeter(meter.meter_id)"
                              class="inline-flex bg-red-50 rounded-md p2 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                            >
                              <XIcon class="h-3 w-3" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    for="location"
                    class="text-sm font-medium leading-6 text-gray-900"
                    >Add Meter</label
                  >

                  <select
                    id="meter"
                    name="meter"
                    @change="addMeter($event)"
                    class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option
                      v-for="[meter_id, meter] in meter_map"
                      :key="meter_id"
                      :value="meter._id"
                    >
                      {{ meter.reference_number }} : {{ meter.description }} ({{
                        meter.type
                      }})
                    </option>
                  </select>
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div v-if="userAlertBottom.status == 'success'">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  {{ userAlertBottom.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="addBuilding === true" class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              v-on:click="createBuilding"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Building
            </button>
          </div>
        </div>

        <div v-else class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              v-on:click="deleteBuilding"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete
            </button>
            <button
              type="submit"
              v-on:click="updateBuilding"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { Switch } from '@headlessui/vue';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import { GoogleMap, Marker } from 'vue3-google-map';
import VuePdfEmbed from 'vue-pdf-embed';
import moment from 'moment';
import { saveAs, encodeBase64 } from 'file-saver';
import { writeFile } from 'fs-web';
import { ref } from 'vue';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
  DocumentDownloadIcon,
  UsersIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  LightningBoltIcon,
  CurrencyEuroIcon,
  CloudUploadIcon,
  OfficeBuildingIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  name: 'Add',
  setup() {
    const file = ref(null);

    const handleFileUpload = async () => {
      // debugger;
      // console.log('selected file', file.value.files);
      //Upload to server
      this.building.image = file.value.files;
    };

    return {
      handleFileUpload,
      file,
    };
  },
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    DocumentDownloadIcon,
    ServerResponse,
    AlertBanner,
    GoogleMap,
    Marker,
    VuePdfEmbed,
    moment,
    saveAs,
    encodeBase64,
    writeFile,
    ArrowDownIcon,
    ArrowUpIcon,
    LightningBoltIcon,
    CurrencyEuroIcon,
    CloudUploadIcon,
    OfficeBuildingIcon,
  },

  data() {
    return {
      chart: null,
      building: {
        meters: [],
      },
      serverResponse: '',
      streamNameValidationError: false,
      userAlertBottom: {
        status: '',
        message: '',
      },
      addBuilding: false,
      geoMessage: '',
      storeLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      googleMapsAPIKey: '',
      meter_map: new Map(),
      series_data: [],
      show_tredwell: false,
      show_induchem_ie: false,
      show_induchem_uk: false,
      temp_map: new Map(),
      master_date_list: [],
      building_meters: [],
      running_total_kwh_2021: 0,
      running_total_kwh_2022: 0,
      running_total_cost_2021: 0,
      running_total_cost_2022: 0,
      running_total_co2_2021: 0,
      running_total_co2_2022: 0,
      co2_emission_factor_gas: 202.9,
      co2_emission_factor_electricity: 347.8,
    };
  },

  methods: {
    validateStreamName(event) {
      const regex = /^[a-z0-9-]+$/;
      if (regex.test(event.target.value)) {
        this.streamNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.streamNameValidationError = true;
      }
    },
    abbreviateNumber(number) {
      // console.log('abbreviatingNumber: ' + number);
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) {
        // console.log('tier == 0');
        return number.toFixed(1);
      }

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;
      // console.log('returning: ' + scaled.toFixed(1) + suffix);

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
    abbreviateWeight(number) {
      // console.log('abbreviatingNumber: ' + number);
      const SI_SYMBOL = ['', 'KG', 'T'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) {
        // console.log('tier == 0');
        return number;
      }

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;
      // console.log('returning: ' + scaled.toFixed(1) + suffix);

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YY');
      }
    },

    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      // console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    updateBuilding() {
      //Let's set the location data on the building
      if (this.storeLocation) {
        this.building.latitude = this.center.lat;
        this.building.longitude = this.center.lng;
      }

      axios
        .put('/buildings/' + this.$route.params.id, this.building)
        .then((res) => {
          this.raiseSuccessAlert('Successfully updated your Building!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    createBuilding() {
      axios
        .post('/buildings', this.building)
        .then((res) => {
          this.raiseSuccessAlert('Successfully created your Building!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    deleteBuilding() {
      axios
        .delete('/buildings/' + this.$route.params.id)
        .then((res) => {
          this.raiseSuccessAlert('Successfully deleted your Building!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    download(pdf_id) {
      // var blob = new Blob([JSON.stringify(data)], {
      //   type: 'application/pdf',
      // });
      // saveAs(blob, 'hello_world.pdf');
      //console.log(data);
      // console.log('getting pdf: ' + pdf_id);

      axios({
        method: 'get',
        url: '/pdfs/' + pdf_id,
        responseType: 'arraybuffer',
      }).then((response) => {
        // console.log(response.data);

        let blob = new Blob([response.data], {
            type: 'application/pdf',
            headers: ['content-type'],
          }),
          url = window.URL.createObjectURL(blob);

        window.open(url); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      });
    },
    addMeter(event) {
      // console.log('addMeter called with ');
      // console.log(event.target.value);

      //let's only add this if it's not already added
      let already_added = false;
      for (let meter of this.building.meters) {
        if (meter.meter_id == event.target.value) {
          already_added = true;
        }
      }
      if (!already_added)
        this.building.meters.push({ meter_id: event.target.value });
      this.building_meters.push(this.meter_map.get(event.target.value));
    },

    removeMeter(meter_id) {
      let meter_array = this.building.meters;
      let new_meter_array = [];
      let new_meter_objects_array = [];
      for (let meter of meter_array) {
        if (meter.meter_id != meter_id) {
          new_meter_array.push(meter);
          new_meter_objects_array.push(this.meter_map.get(meter.meter_id));
        }
      }
      this.building.meters = new_meter_array;
      this.building_meters = new_meter_objects_array;
    },

    refreshLocation() {
      var coords = this.geoMessage.split(',');
      this.center.lat = parseFloat(coords[0]);
      this.center.lng = parseFloat(coords[1]);

      // console.log(
      //   'refresh location called - geoMessage: ' +
      //     this.center.lat +
      //     ':' +
      //     this.center.lng
      // );

      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        //   console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    getMeterData() {
      // console.log('getMeterData called');
      let meter_count = this.building.meters.length;
      let count = 0;
      for (let meter of this.building.meters) {
        //  console.log('calling: ' + `/meters/${meter.meter_id}`);
        axios
          .get(`/meters/${meter.meter_id}`)
          .then((res) => {
            count++;
            let data = [];
            let meter = res.data.data;
            // console.log('meter object');
            // console.log(meter);
            //Let's iterate through the reports and build the series
            for (let monthly_report of meter.consumption_reports) {
              data.push({
                x: monthly_report.start_date,
                y: Math.round(monthly_report.usage),
              });
              if (new Date(monthly_report.start_date).getFullYear() == 2021) {
                this.running_total_kwh_2021 =
                  this.running_total_kwh_2021 + monthly_report.usage;
                let monthly_cost = monthly_report.usage * meter.price_per_unit;
                this.running_total_cost_2021 =
                  this.running_total_cost_2021 + monthly_cost;

                let emission_factor = 0;
                if (meter.type == 'gas') {
                  emission_factor = this.co2_emission_factor_gas;
                } else if (meter.type == 'electricity') {
                  emission_factor = this.co2_emission_factor_electricity;
                }
                this.running_total_co2_2021 =
                  this.running_total_co2_2021 +
                  monthly_report.usage * emission_factor;
              } else if (
                new Date(monthly_report.start_date).getFullYear() == 2022
              ) {
                this.running_total_kwh_2022 =
                  this.running_total_kwh_2022 + monthly_report.usage;

                let monthly_cost = monthly_report.usage * meter.price_per_unit;
                this.running_total_cost_2022 =
                  this.running_total_cost_2022 + monthly_cost;

                let emission_factor = 0;
                if (meter.type == 'gas') {
                  emission_factor = this.co2_emission_factor_gas;
                } else if (meter.type == 'electricity') {
                  emission_factor = this.co2_emission_factor_electricity;
                }
                this.running_total_co2_2022 =
                  this.running_total_co2_2022 +
                  monthly_report.usage * emission_factor;
              }

              //Let's update the master_date_list
              let date_string =
                new Date(monthly_report.start_date).getMonth() +
                1 +
                '/' +
                new Date(monthly_report.start_date).getFullYear();
              // console.log('date_string: ' + date_string);
              if (!this.master_date_list.includes(date_string)) {
                this.master_date_list.push(date_string);
              }
            }
            // console.log('series_data: ');
            // console.log(data);

            this.series_data.push({
              name: meter.type,
              type: 'bar',
              data: data,
            });
            // console.log('adding series: ');
            // console.log(meter.type);

            if (count >= meter_count) {
              //Now that we have the data collected, let's get the ambient temerature data to add to the graphs
              let series_data = this.series_data[0];
              let temp_data = [];
              for (let data of series_data.data) {
                //console.log('monthly_report.start_date: ' + data.x);
                let date = new Date(data.x);
                let date_string =
                  date.getMonth() + 1 + '/' + date.getFullYear();
                let temp = this.temp_map.get(date_string);
                temp_data.push({ x: data.x, y: temp });
                //console.log({ x: data.x, y: temp });
              }
              this.series_data.push({
                name: 'ambient temperature',
                type: 'line',
                data: temp_data,
              });
              this.loadGraph();
            }
          })
          .catch();
      }
    },
    loadGraph() {
      // console.log('loadGraph() series_data:');
      // console.log(this.series_data);
      let series_type = 'electricty';
      this.chart = {
        reference_number: 'reference_number',
        options: {
          theme: {
            palette: 'palette2',
          },

          chart: {
            stacked: false,
            type: 'bar',
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
          },
          stroke: {
            show: true,
            curve: ['smooth', 'smooth', 'smooth'],
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
          },
          title: {
            text: this.building.name,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238',
            },
          },
          subtitle: {
            text: this.building.description,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 20,
            floating: false,
            style: {
              fontSize: '12px',
              fontWeight: 'normal',
              fontFamily: undefined,
              color: '#9699a2',
            },
          },
          dataLabels: {
            enabled: false,
          },

          tooltip: {
            enabled: true,
            x: {
              show: true,
              format: 'MM yyyy',
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            autoSelected: 'zoom',
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: true,
              format: 'MM yy',
            },
          },
          yaxis: [
            {
              seriesName: 'electricity',
              show: true,
              title: {
                text: 'kwh',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
              },
              labels: {
                formatter: function (value) {
                  return value / 1000 + 'k';
                },
              },
            },
            {
              seriesName: 'electricity',
              show: false,
              title: {
                text: 'kwh',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
              },
            },
            {
              seriesName: 'ambient temperature',
              opposite: true,
              title: {
                text: 'ambient temperature',
                rotate: 90,
                offsetX: 0,
                offsetY: 0,
              },
            },
          ],
        },
        series: this.series_data,
      };
    },
  },

  created() {
    // console.log('created() called with: ' + this.$route);
    // console.log('this.$route.path:' + this.$route.path);

    this.building.core_account_name = this.$store.state.clime_account.name;
    this.building.core_project_name = this.$store.state.clime_project.name;
    this.googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    if (this.$route.path === '/buildings/new') {
      //  console.log('Creating new building');
      this.addBuilding = true;
    } else {
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;

      axios
        .get('/buildings/' + this.$route.params.id)
        .then((res) => {
          //   console.log(res.data);
          this.building = res.data.data;

          this.center.lat = this.building.latitude;
          this.center.lng = this.building.longitude;
          this.getMeterData();
          this.geoMessage = this.center.lat + ',' + this.center.lng;
          this.showMap = true;
          this.serverResponse = JSON.stringify(res, null, 2);

          if (this.building.name == 'Induchem Cork') {
            this.show_induchem_ie = true;
          } else if (this.building.name == 'Induchem UK') {
            this.show_induchem_uk = true;
          } else if (this.building.name == 'Tredwell Electrical') {
            this.show_tredwell = true;
          }

          //Now - let's get a list of all the meterts
          axios
            .get(
              '/meters?core_account_name=' +
                currentAccountSession +
                '&core_project_name=' +
                currentProjectSession
            )
            .then((res) => {
              this.serverResponse = JSON.stringify(res, null, 2);

              for (let meter of res.data.data) {
                this.meter_map.set(meter._id, meter);
              }

              // console.log('----meter map----');
              // console.log(this.meter_map);

              //Let's add the current meters that are on the building to the building_meters array
              for (let meter of this.building.meters) {
                //  console.log('meter_id: ' + meter.meter_id);
                this.building_meters.push(this.meter_map.get(meter.meter_id));
                console.log(
                  'adding meter to builing_meters[]: ' +
                    this.meter_map.get(meter.meter_id).reference_number
                );
              }
              // console.log('building meters');
              // console.log(this.building_meters);
            })
            .catch((err) => {
              this.raiseErrorAlert(err);
              this.serverResponse = JSON.stringify(err.response, null, 2);
            });
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    }

    this.temp_map.set('1/2020', 7.5);
    this.temp_map.set('2/2020', 7.2);
    this.temp_map.set('3/2020', 7.2);
    this.temp_map.set('4/2020', 10.0);
    this.temp_map.set('5/2020', 11.7);
    this.temp_map.set('6/2020', 14.1);
    this.temp_map.set('7/2020', 15);
    this.temp_map.set('8/2020', 15.8);
    this.temp_map.set('9/2020', 14.2);
    this.temp_map.set('10/2020', 11);
    this.temp_map.set('11/2020', 9.4);
    this.temp_map.set('12/2020', 6.9);
    this.temp_map.set('1/2021', 5.8);
    this.temp_map.set('2/2021', 6.9);
    this.temp_map.set('3/2021', 8);
    this.temp_map.set('4/2021', 8.4);
    this.temp_map.set('5/2021', 10.2);
    this.temp_map.set('6/2021', 14);
    this.temp_map.set('7/2021', 17);
    this.temp_map.set('8/2021', 15.8);
    this.temp_map.set('9/2021', 15.4);
    this.temp_map.set('10/2021', 12.4);
    this.temp_map.set('11/2021', 9.4);
    this.temp_map.set('12/2021', 8.9);
    this.temp_map.set('1/2022', 7.4);
    this.temp_map.set('2/2022', 8.2);
    this.temp_map.set('3/2022', 8.2);
    this.temp_map.set('4/2022', 9.6);
    this.temp_map.set('5/2022', 12.4);
    this.temp_map.set('6/2022', 13.8);
    this.temp_map.set('7/2022', 16.4);
    this.temp_map.set('8/2022', 17.1);
    this.temp_map.set('9/2022', 14.9);
    this.temp_map.set('10/2022', 13.1);
    this.temp_map.set('11/2022', 10.2);
    this.temp_map.set('12/2022', 6.3);
    this.temp_map.set('1/2023', 7.2);
    this.temp_map.set('2/2023', 8.5);
    this.temp_map.set('3/2023', 7);
  },
};
</script>
<script setup>
// const stats = [
//   {
//     id: 1,
//     name: 'Total MWh Used',
//     stat: '270 MWh',
//     icon: UsersIcon,
//     change: '122',
//     changeType: 'increase',
//   },
//   {
//     id: 2,
//     name: 'Avg. Open Rate',
//     stat: '58.16%',
//     icon: UsersIcon,
//     change: '5.4%',
//     changeType: 'increase',
//   },
//   {
//     id: 3,
//     name: 'Avg. Click Rate',
//     stat: '24.57%',
//     icon: UsersIcon,
//     change: '3.2%',
//     changeType: 'decrease',
//   },
//];
</script>
