<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-6 px-6">
    <div>
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/settings'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Settings</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="#"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >Preferences</a
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Start  -->
    <div class="px-6">
      <div class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <div
            class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12 sm:w-full"
          >
            <div class="sm:col-span-6">
              <div class="flex">
                <p class="pr-3 text-lg leading-6 font-medium text-gray-900">
                  Homepage Report
                </p>
              </div>

              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Choose the report to load on the Home screen. This will be the
                first report to load every time you log in
              </p>
            </div>
          </div>
        </div>
        <div class="px-6 py-6 flex border-t sm:border-gray-200 sm:pt-5">
          <div
            class="mt-6 grid grid-cols-12 gap-y-6 gap-x-4 sm:grid-cols-12 sm:w-full"
          >
            <div class="flex sm:col-span-6 col-span-12">
              <!-- <input
                type="text"
                v-model="account.webhook.url"
                class="flex sm:w-full w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
              /> -->
            </div>

            <div class="sm:col-span-5 sm:text-right col-span-12 text-left">
              <div v-if="!saving">
                <button
                  type="button"
                  @click="updateHomepageReport"
                  class="items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  Update Homepage Report
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="items-center mr-2 px-7 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white bg-green-50"
                >
                  Updated
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import { Switch } from '@headlessui/vue';
import axios from 'axios';
import LoadingWidget from '../../components/LoadingWidget.vue';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    Switch,
    LoadingWidget,
    ServerResponse,
    AlertBanner,
    CheckCircleIcon,
    XCircleIcon,
  },
  data() {
    return {
      showLoading: false,
      saving: false,
      webhookMessage: '',
      serverResponse: '',
    };
  },
  created() {
    const currentAccountSession = this.$store.state.clime_account.name;
    axios
      .get('/accounts?account_name=' + currentAccountSession)
      .then((res) => {
        this.serverResponse = JSON.stringify(res, null, 2);
        this.account = res.data.data[0];

        console.log('this.account');
        console.log(this.account);

        //Let's test the webhook url as soon as we come in
        //but only if the webhook is enabled
        if (this.account.webhook.enabled == true) {
          this.testWebhookURL();
        }
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
      });
  },
  methods: {
    updateHomepageReport() {
      this.saving = true;
      axios
        .put('/accounts/' + this.account._id, this.account)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          //Just to give some feeling of action to the user
          //Without this, it feels too quick and there's no sense of change
          setTimeout(() => {
            this.saving = false;
          }, 500);
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.response, null, 2);
          setTimeout(() => {
            this.saving = false;
          }, 500);
        });
    },
  },
};
</script>
