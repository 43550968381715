<template>
  <div />
</template>

<script>
import axios from 'axios';
export default {
  setup() {
    return {};
  },

  components: {},
  data() {
    return {
      user: null,
      default_home: '/dashboard',
    };
  },
  methods: {},
  mounted() {},
  created() {
    const currentAccountSession = this.$store.state.clime_account.name;
    axios
      .get('/users?email=' + this.$auth.user.value.email.replaceAll('+', '%2b'))
      .then((res) => {
        console.log('res.data.count: ' + res.data.count);

        if (res.data.count > 0) {
          this.user = res.data.data[0];
          console.log('user object: ');
          console.log(this.user);

          for (let i = 0; i < this.user.core_accounts.length; i++) {
            //account_homepage is now set at the account level, not the user level
            if (
              this.user.core_accounts[i].account_name ==
              this.$store.state.clime_account.name
            ) {
              if (this.user.core_accounts[i].account_homepage) {
                this.default_home =
                  '/reports/' + this.user.core_accounts[i].account_homepage;
              }

              console.log('setting default_home to ' + this.default_home);
            }
          }
          this.$router.push(this.default_home);
        }
      })
      .catch((err) => {
        this.$router.push(this.default_home);
      });
  },
};
</script>
