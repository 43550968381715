<template>
  <div class="py-6 px-6">
    <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">Meter Detail</h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"> -->
    <!-- Replace with your content -->

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Meter Details: {{ meter.reference_number }}
        </h3>

        <div v-if="chart">
          <div class="filter shadow mt-2 mb-2 border bg-white rounded-md">
            <apexchart
              width="100%"
              height="300"
              :options="chart.options"
              :series="chart.series"
            ></apexchart>
          </div>
        </div>

        <div class="filter shadow mt-10 mb-2 border bg-white rounded-md">
          <div class="px-4 sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-center">
              <div class="sm:flex-auto">
                <h1
                  class="mt-10 text-base font-semibold leading-6 text-gray-900"
                >
                  Invoices
                </h1>
                <p class="mt-2 text-sm text-gray-700">
                  A list of all the invoices available in the Utility account
                </p>
              </div>
            </div>
            <div class="mt-8 flow-root">
              <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-64 overflow-auto"
                >
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Start Date
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          End Date
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Days
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Invoice Number
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          kWh
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Cost
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Download
                        </th>
                      </tr>
                    </thead>

                    <tbody class="divide-y divide-gray-200">
                      <tr v-for="invoice in meter.invoices" :key="invoice">
                        <td
                          class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                        >
                          {{ format_date(invoice.start_date) }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ format_date(invoice.end_date) }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{
                            (new Date(invoice.end_date) -
                              new Date(invoice.start_date)) /
                            (24 * 60 * 60 * 1000)
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ invoice.invoice_number }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{ invoice.kwh.toLocaleString() }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                        >
                          {{
                            parseFloat(invoice.cost.toFixed(0)).toLocaleString()
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-0"
                        >
                          <DocumentDownloadIcon
                            class="flex-shrink-0 h-7 w-7 ml-5 text-gray-400"
                            v-on:click="download(invoice.pdf_id)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ml-0 mt-0">
          <div class="flex items-center">
            <!-- <div class="flex-shrink-0 mt-0 max-w-2xl text-sm text-gray-500">
              Last Sync
              {{
                getTimeDiff(
                  dateMath.diff(
                    new Date(meter.last_seen),
                    new Date(),
                    'minutes',
                    false
                  )
                )
              }}
            </div> -->
            <!-- <div class="ml-4">
              <p class="text-sm text-gray-500">
                <RefreshIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  @click="refresh"
                />
              </p>
            </div> -->
          </div>
        </div>

        <p></p>

        <div class="p-8">
          <dl class="mt-0 grid grid-cols-1 gap-5">
            <dl
              class="mt-5 grid grid-cols-1"
              v-if="
                meter.capabilities && meter.capabilities.includes('electricity')
              "
            >
              <utility-panel
                v-if="
                  meter.capabilities &&
                  meter.capabilities.includes('electricity')
                "
                :reference_number="meter.reference_number"
                :capability="'electricity'"
                :_id="meter._id"
              >
              </utility-panel>
            </dl>
            <dl
              class="mt-5 grid grid-cols-1"
              v-if="meter.capabilities && meter.capabilities.includes('gas')"
            >
              <utility-panel
                v-if="meter.capabilities && meter.capabilities.includes('gas')"
                :reference_number="meter.reference_number"
                :capability="'gas'"
                :_id="meter._id"
              >
              </utility-panel>
            </dl>

            <!-- break in data block  -->
          </dl>
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Reference Number (MPRN/GPRN)
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.reference_number"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Address</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.address"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Description</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.description"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Utility Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <!-- column for utility dropdown  -->
                  <td class="px-0 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div
                      class="px-0 mt-1 w-full relative rounded-md shadow-sm pl-1"
                    >
                      <select
                        v-model="meter.utility_id"
                        class="text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option
                          v-for="utility in utilities"
                          :key="utility.name"
                          :value="utility._id"
                        >
                          {{ utility.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Credentials Owner Email
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.credential_owner.email"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Credentials username
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.credentials.username"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Credentials Password
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="password"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.credentials.password"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Meter Type</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <select
                      class="mt-1 px-2 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md"
                      v-model="meter.type"
                    >
                      <option disabled value="">
                        Please select Meter Type
                      </option>
                      <option v-for="type in meter_type_array" :key="type">
                        {{ type }}
                      </option>
                    </select>
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Meter Units</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <select
                      class="mt-1 px-2 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md"
                      v-model="meter.units"
                    >
                      <option disabled value="">
                        Please select Meter units
                      </option>
                      <option v-for="units in meter_units_array" :key="units">
                        {{ units }}
                      </option>
                    </select>
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Enter cost per unit
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.price_per_unit"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Last Data Refresh
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="meter.last_data_refresh"
                    />
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div v-if="userAlertBottom.status == 'success'">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  {{ userAlertBottom.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="addMeter === true" class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              v-on:click="createMeter"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Meter
            </button>
          </div>
        </div>

        <div v-else class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              v-on:click="deleteMeter"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete
            </button>
            <button
              type="submit"
              v-on:click="updateMeter"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { Switch } from '@headlessui/vue';
import ServerResponse from '../components/ServerResponse';
import DataStreamPanel from '../components/DataStreamPanel.vue';
import UtilityPanel from '../components/UtilityPanel';
import AlertBanner from '../components/AlertBanner';
import { GoogleMap, Marker } from 'vue3-google-map';
import VuePdfEmbed from 'vue-pdf-embed';
import moment from 'moment';
import { saveAs, encodeBase64 } from 'file-saver';
import { writeFile } from 'fs-web';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
  DocumentDownloadIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    DocumentDownloadIcon,
    ServerResponse,
    AlertBanner,
    GoogleMap,
    Marker,
    DataStreamPanel,
    UtilityPanel,
    VuePdfEmbed,
    moment,
    saveAs,
    encodeBase64,
    writeFile,
  },

  data() {
    return {
      chart: null,
      meter: {
        tags: [],
        reference_number: null,
        core_account_name: null,
        core_project_name: null,
        last_seen: null,
        description: null,
        credential_owner: {
          name: null,
          email: null,
        },
        credentials: {
          username: null,
          password: null,
        },
      },
      dateMath,
      serverResponse: '',
      streamNameValidationError: false,
      userAlertBottom: {
        status: '',
        message: '',
      },
      addMeter: false,
      geoMessage: '',
      storeLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      googleMapsAPIKey: '',
      utilities: [],
      meter_type_array: ['electricity', 'gas', 'water'],
      meter_units_array: ['kwh', 'd3', 'hcf'],
    };
  },

  methods: {
    validateStreamName(event) {
      const regex = /^[a-z0-9-]+$/;
      if (regex.test(event.target.value)) {
        this.streamNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.streamNameValidationError = true;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YY');
      }
    },

    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      // console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    updateMeter() {
      //Let's set the location data on the meter
      if (this.storeLocation) {
        this.meter.latitude = this.center.lat;
        this.meter.longitude = this.center.lng;
      }

      axios
        .put('/meters/' + this.$route.params.id, this.meter)
        .then((res) => {
          this.raiseSuccessAlert('Successfully updated your Meter!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    createMeter() {
      axios
        .post('/meters', this.meter)
        .then((res) => {
          this.raiseSuccessAlert('Successfully created your Meter!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    deleteMeter() {
      axios
        .delete('/meters/' + this.$route.params.id)
        .then((res) => {
          this.raiseSuccessAlert('Successfully deleted your Meter!');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    download(pdf_id) {
      // var blob = new Blob([JSON.stringify(data)], {
      //   type: 'application/pdf',
      // });
      // saveAs(blob, 'hello_world.pdf');
      //console.log(data);
      console.log('getting pdf: ' + pdf_id);

      axios({
        method: 'get',
        url: '/pdfs/' + pdf_id,
        responseType: 'arraybuffer',
      }).then((response) => {
        // console.log(response.data);

        let blob = new Blob([response.data], {
            type: 'application/pdf',
            headers: ['content-type'],
          }),
          url = window.URL.createObjectURL(blob);

        window.open(url); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      });
    },

    refreshLocation() {
      var coords = this.geoMessage.split(',');
      this.center.lat = parseFloat(coords[0]);
      this.center.lng = parseFloat(coords[1]);

      console.log(
        'refresh location called - geoMessage: ' +
          this.center.lat +
          ':' +
          this.center.lng
      );

      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    loadGraph() {
      let series_data = [];
      let data = [];
      //Let's iterate through the reports and build the series
      for (let monthly_report of this.meter.consumption_reports) {
        data.push({
          x: monthly_report.start_date,
          y: Math.round(monthly_report.usage),
        });
      }

      series_data.push({ name: 'monthly usage', data: data });

      console.log('series_data');
      console.log(series_data);

      let series_type = 'electricty';
      this.chart = {
        reference_number: 'reference_number',
        options: {
          theme: {
            palette: 'palette2',
          },

          chart: {
            stacked: false,
            type: 'bar',
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
          },
          title: {
            text: this.meter.reference_number,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238',
            },
          },
          subtitle: {
            text: this.meter.description,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 20,
            floating: false,
            style: {
              fontSize: '12px',
              fontWeight: 'normal',
              fontFamily: undefined,
              color: '#9699a2',
            },
          },
          dataLabels: {
            enabled: false,
          },

          tooltip: {
            enabled: true,
            x: {
              show: true,
              format: 'MM yyyy',
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            autoSelected: 'zoom',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: true,
              format: 'MM yy',
            },
          },
          yaxis: {
            title: {
              text: 'kwh',
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
        series: series_data,
      };
    },
  },

  created() {
    console.log('created() called with: ' + this.$route);
    console.log('this.$route.path:' + this.$route.path);

    this.meter.core_account_name = this.$store.state.clime_account.name;
    this.meter.core_project_name = this.$store.state.clime_project.name;
    this.googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    if (this.$route.path === '/meters/new') {
      console.log('Creating new meter');
      this.addMeter = true;
      //this.meter.core_account_name = this.$store.state.account.name;
    } else {
      axios
        .get('/meters/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.meter = res.data.data;
          this.loadGraph();
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    }

    axios
      .get('/utilities')
      .then((res) => {
        this.utilities = res.data.data;
        console.log(res);
        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
      });
  },
};
</script>
